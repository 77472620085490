"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrengthCalcV4 = void 0;
const guid_typescript_1 = require("guid-typescript");
const __1 = require("..");
const Chord_1 = require("./Chord");
const Damage_1 = require("./Damage");
const Error_1 = require("./Error");
const Sector_1 = require("./Sector");
const STRCALC_1 = require("./STRCALC");
var Tool;
(function (Tool) {
    Tool[Tool["Calliper"] = 0] = "Calliper";
    Tool[Tool["Tape"] = 1] = "Tape";
    Tool[Tool["RuleOf6"] = 2] = "RuleOf6";
})(Tool || (Tool = {}));
class StrengthCalcV4 {
    constructor(pOriginalCircumference, pShellRotCirc, pSectors, pChords) {
        this.selectedDamageGuid = guid_typescript_1.Guid.createEmpty();
        this.cSectors = [];
        this.cChords = [];
        this.Sectors = [];
        this.Chords = [];
        this.cErrorMessages = [];
        this.cDecayPropertyList = [];
        this.cOriginalCircumference = 0.0;
        this.cShellRotThisCycle = 0.0;
        this._RemainingPoleArea = 0;
        this._OverlapExists = false;
        this.n = 500;
        this.BMAxis = 0;
        this._kVal = 500;
        this._GridSquareRatio = 1.0;
        this._IxxCG = 0;
        this._IyyCG = 0;
        this._IxyCG = 0;
        this._CGx = 0;
        this._CGy = 0;
        this._MOImax = 0;
        this._MOImin = 0;
        this._Omax = 0;
        this._Omin = 0;
        this.Cr = null;
        this.Co = null;
        this.SectorsArea = 0;
        this.SectorsCGx = 0;
        this.SectorsCGy = 0;
        this.SectorsIxx = 0;
        this.SectorsIyy = 0;
        this.SectorsIxy = 0;
        this.SectorsIxxCG = 0;
        this.SectorsIyyCG = 0;
        this.SectorsIxyCG = 0;
        this.ChordsArea = 0;
        this.ChordsCGx = 0;
        this.ChordsCGy = 0;
        this.ChordsIxx = 0;
        this.ChordsIyy = 0;
        this.ChordsIxy = 0;
        this.ChordsIxxCG = 0;
        this.ChordsIyyCG = 0;
        this.ChordsIxyCG = 0;
        this.OverlapCGx = 0;
        this.OverlapCGy = 0;
        this.OverlapIxx = 0;
        this.OverlapIyy = 0;
        this.OverlapIxy = 0;
        this.OverlapArea = 0;
        this._OuterSurfaceRadii = new Array();
        this._OuterFiberDistanceMax = new Array();
        this._OuterFiberDistanceMin = new Array();
        this._OuterFiberDistance = new Array();
        this._RSRmin = new Array();
        this._RSRmax = new Array();
        this._NAxis = new Array();
        this._NAxisDiff = new Array();
        this._SectionModulusReciprocal = new Array();
        this.Calculating = false;
        this.cOriginalCircumference = pOriginalCircumference !== null && pOriginalCircumference !== void 0 ? pOriginalCircumference : this.cOriginalCircumference;
        this.cShellRotThisCycle = pShellRotCirc !== null && pShellRotCirc !== void 0 ? pShellRotCirc : this.cShellRotThisCycle;
        this.cSectors = pSectors !== null && pSectors !== void 0 ? pSectors : this.cSectors;
        this.cChords = pChords !== null && pChords !== void 0 ? pChords : this.cChords;
    }
    get OGCirc() { return this.cOriginalCircumference; }
    set OGCirc(value) { this.cOriginalCircumference = value; }
    get SRCirc() { return this.cShellRotThisCycle; }
    set SRCirc(value) { this.cShellRotThisCycle = value; }
    get strSectors() { return "SECTORS"; }
    ;
    get strChords() { return "CHORDS"; }
    ;
    get strSegments() { return "SEGMENTS"; }
    ;
    get strSector() { return "SECTOR"; }
    ;
    get strChord() { return "CHORD"; }
    ;
    get strSegment() { return "SEGMENT"; }
    ;
    get strMeasurements() { return "MEASURE"; }
    ;
    get strResults() { return "RESULT"; }
    ;
    get strArcLen() { return "ARCLEN"; }
    ;
    get strThick() { return "THICK"; }
    ;
    get strShell() { return "SHELL"; }
    ;
    get strDepth() { return "DEPTH"; }
    ;
    get strSource() { return "SOURCE"; }
    ;
    get strOrientation() { return "ORIENT"; }
    ;
    get strCircumference() { return "CIRC"; }
    ;
    get strShellRot() { return "SR"; }
    ;
    get strEffectiveCircumference() { return "EFFCIRC"; }
    ;
    get strRemainingStrength() { return "REMSTR"; }
    ;
    get DRCON() { return 180.0 / Math.PI; }
    ;
    get strExpPocks() { return "EXPPOCKS"; }
    ;
    get strExpPock() { return "EXPPOCK"; }
    ;
    get strEncPocks() { return "ENCPOCKS"; }
    ;
    get strEncPock() { return "ENCPOCK"; }
    ;
    get strMechDmgs() { return "MECHDMGS"; }
    ;
    get strMechDmg() { return "MECHDMG"; }
    ;
    get strHlwPocks() { return "HLWPOCKS"; }
    ;
    get strHlwPock() { return "HLWPOCK"; }
    ;
    get OGRadius() { return this.cOriginalCircumference / (Math.PI * 2); }
    get SRRadius() { return this.cShellRotThisCycle / (Math.PI * 2); }
    get OGArea() { return Math.pow(this.OGRadius, 2) * Math.PI; }
    get SRArea() { return Math.pow(this.SRRadius, 2) * Math.PI; }
    get OGMOI() { return Math.pow(this.OGRadius, 4) * .25 * Math.PI; }
    get SRMOI() { return Math.pow(this.SRRadius, 4) * .25 * Math.PI; }
    get PSRArea() { return this.OGArea - this.SRArea; }
    get PSRMOI() { return this.OGMOI - this.SRMOI; }
    get RemainingPoleArea() { return this._RemainingPoleArea; }
    set RemainingPoleArea(value) { this._RemainingPoleArea = value; }
    get OverlapExists() { return this._OverlapExists; }
    set OverlapExists(value) { this._OverlapExists = value; }
    get kVal() { return this._kVal; }
    set kVal(value) { this._kVal = value; }
    get GridSquareRatio() { return this._GridSquareRatio; }
    set GridSquareRatio(value) { this._GridSquareRatio = value; }
    get IxxCG() { return this._IxxCG; }
    set IxxCG(value) { this._IxxCG = value; }
    get IyyCG() { return this._IyyCG; }
    set IyyCG(value) { this._IyyCG = value; }
    get IxyCG() { return this._IxyCG; }
    set IxyCG(value) { this._IxyCG = value; }
    get CGx() { return this._CGx; }
    set CGx(value) { this._CGx = value; }
    get CGy() { return this._CGy; }
    set CGy(value) { this._CGy = value; }
    get MOImax() { return this._MOImax; }
    set MOImax(value) { this._MOImax = value; }
    get MOImin() { return this._MOImin; }
    set MOImin(value) { this._MOImin = value; }
    get Omax() { return this._Omax; }
    set Omax(value) { this._Omax = value; }
    get Omin() { return this._Omin; }
    set Omin(value) { this._Omin = value; }
    get Initialized() {
        if ((this.TotalDamages == 0) &&
            (this.cOriginalCircumference == 0 &&
                this.cShellRotThisCycle == 0))
            return false;
        return true;
    }
    get TotalDamages() {
        try {
            return this.Sectors.length + this.Chords.length;
        }
        catch (NullReferenceException) {
            return 0;
        }
    }
    get ErrorMessages() {
        return this.cErrorMessages;
    }
    set ErrorMessages(value) {
        this.cErrorMessages = value;
    }
    get HasErrors() {
        let totalDamages = this.Sectors.length + this.Chords.length;
        if (this.cOriginalCircumference == 0 && this.cShellRotThisCycle == 0 && totalDamages == 0)
            return false;
        return this.cErrorMessages.length > 0 ? true : false;
    }
    get BucklingFactor() {
        return this._BucklingFactor;
    }
    set BucklingFactor(value) {
        this._BucklingFactor = value;
    }
    get EffectiveCircumferenceAtLoL() {
        return this._EffectiveCircumferenceAtLoL;
    }
    set EffectiveCircumferenceAtLoL(value) {
        this._EffectiveCircumferenceAtLoL = value;
    }
    get EffectiveCircumferenceAtLoLPrecise() {
        return this._EffectiveCircumferenceAtLoLPrecise;
    }
    set EffectiveCircumferenceAtLoLPrecise(value) {
        this._EffectiveCircumferenceAtLoLPrecise = value;
    }
    get MinEffectiveCircumference() {
        return this._MinEffectiveCircumference;
    }
    set MinEffectiveCircumference(value) {
        this._MinEffectiveCircumference = value;
    }
    get MinEffectiveCircumferencePrecise() {
        return this._MinEffectiveCircumferencePrecise;
    }
    set MinEffectiveCircumferencePrecise(value) {
        this._MinEffectiveCircumferencePrecise = value;
    }
    get RemainingStrengthAtLoL() {
        return Math.floor(this.RemainingStrengthAtLoLPrecise);
    }
    get RemainingStrengthAtLoLPrecise() {
        return this._RemainingStrengthAtLoLPrecise;
    }
    set RemainingStrengthAtLoLPrecise(value) {
        this._RemainingStrengthAtLoLPrecise = value;
    }
    get MinRemainingStrength() {
        return Math.floor(this.MinRemainingStrengthPrecise);
    }
    get MinRemainingStrengthPrecise() {
        return this._MinRemainingStrengthPrecise;
    }
    set MinRemainingStrengthPrecise(value) {
        this._MinRemainingStrengthPrecise = value;
    }
    get MaxRemainingStrength() {
        return Math.floor(this.MaxRemainingStrengthPrecise);
    }
    get MaxRemainingStrengthPrecise() {
        return this._MaxRemainingStrengthPrecise;
    }
    set MaxRemainingStrengthPrecise(value) {
        this._MaxRemainingStrengthPrecise = value;
    }
    get MaxRemDegBend() {
        return this._MaxRemDegBend;
    }
    set MaxRemDegBend(value) {
        this._MaxRemDegBend = value;
    }
    get MinRemDegBend() {
        return this._MinRemDegBend;
    }
    set MinRemDegBend(value) {
        this._MinRemDegBend = value;
    }
    get OuterSurfaceRadii() {
        return this._OuterSurfaceRadii;
    }
    set OuterSurfaceRadii(value) {
        this._OuterSurfaceRadii = value;
    }
    get OuterFiberDistanceMax() {
        return this._OuterFiberDistanceMax;
    }
    set OuterFiberDistanceMax(value) {
        this._OuterFiberDistanceMax = value;
    }
    get OuterFiberDistanceMin() {
        return this._OuterFiberDistanceMin;
    }
    set OuterFiberDistanceMin(value) {
        this._OuterFiberDistanceMin = value;
    }
    get OuterFiberDistance() {
        return this._OuterFiberDistance;
    }
    set OuterFiberDistance(value) {
        this._OuterFiberDistance = value;
    }
    get RSRmin() {
        return this._RSRmin;
    }
    set RSRmin(value) {
        this._RSRmin = value;
    }
    get RSRmax() {
        return this._RSRmax;
    }
    set RSRmax(value) {
        this._RSRmax = value;
    }
    get NAxis() {
        return this._NAxis;
    }
    set NAxis(value) {
        this._NAxis = value;
    }
    get NAxisDiff() {
        return this._NAxisDiff;
    }
    set NAxisDiff(value) {
        this._NAxisDiff = value;
    }
    get SectionModulusReciprocal() {
        return this._SectionModulusReciprocal;
    }
    set SectionModulusReciprocal(value) {
        this._SectionModulusReciprocal = value;
    }
    GetSectorByGuid(guid) {
        this.cSectors.forEach(sec => {
            if (sec.DamageGuid == guid)
                return sec;
        });
        return null;
    }
    GetChordByGuid(guid) {
        this.cChords.forEach(ch => {
            if (ch.DamageGuid == guid)
                return ch;
        });
        return null;
    }
    AddMechanicalDamage(cArcLen = 0.0, cOrientation = 0, cSource = __1.SourceTypes.Manual) {
        this.AddChord(cArcLen, cOrientation, Damage_1.DamageTypes.MechanicalDamage, cSource);
    }
    AddChord(cArcLen = 0.0, cOrientation = 0, cDamage = Damage_1.DamageTypes.Unknown, cSource = __1.SourceTypes.Manual) {
        let chordTemp = new Chord_1.Chord();
        if (cArcLen > 0.0)
            chordTemp.ArcLen = cArcLen;
        chordTemp.Orientation = cOrientation;
        chordTemp.DamageType = cDamage;
        chordTemp.Source = cSource;
        this.cChords.push(chordTemp);
    }
    AddChord2(chord) {
        this.cChords.push(chord);
    }
    RemoveChord(index) {
        if (this.cChords.length > index) {
            this.cChords.splice(index, 1);
            return true;
        }
        return false;
    }
    RemoveChord1(chord) {
        if (this.cChords.indexOf(chord) > -1) {
            let arr = this.cChords.filter(ele => ele != chord);
            this.cChords = arr;
            return true;
        }
        return false;
    }
    ClearChords() {
        this.cChords = [];
    }
    AddEnclosedPocket(cArcLen = 0.0, cOrientation = 0, cDepth = 0.0, cThickness = 0.0, cSource = __1.SourceTypes.Manual) {
        if (cArcLen === 0) { cArcLen = 0.0; }
        if (cOrientation === 0) { cOrientation = 0; }
        if (cDepth === 0) { cDepth = 0.0; }
        if (cThickness === 0) { cThickness = 0.0; }
        if (cSource === 0) { cSource = 0; }
        this.AddSector(cArcLen, cOrientation, cDepth, cThickness, Damage_1.DamageTypes.EnclosedPocket, cSource);
    }
    AddExposedPocket(cArcLen = 0.0, cOrientation = 0, cDepth = 0.0, cSource = __1.SourceTypes.Manual) {
        this.AddSector(cArcLen, cOrientation, cDepth, 0, Damage_1.DamageTypes.ExposedPocket, cSource);
    }
    AddHollowPole(cArcLen = 0.0, cDepth = 0.0, cThickness = [], cSource = __1.SourceTypes.Manual) {
        for (let i = 0; i < 4; i++)
            this.AddSector(cArcLen, i * 90, cDepth, parseFloat(cThickness[i]), Damage_1.DamageTypes.HollowPocket, cSource);
    }
    AddSector(cArcLen = 0.0, cOrientation = 0, cDepth = 0.0, cThickness = 0.0, cDamageType = Damage_1.DamageTypes.Unknown, cSource = __1.SourceTypes.Manual) {
        if (cArcLen === 0) { cArcLen = 0.0; }
        if (cOrientation === 0) { cOrientation = 0; }
        if (cDepth === 0) { cDepth = 0.0; }
        if (cThickness === 0) { cThickness = 0.0; }
        if (cDamageType === 0) { cDamageType = 0; }
        if (cSource === 0) { cSource = 0; }
        let SectorTemp = new Sector_1.Sector();
        if (cArcLen > 0.0)
            SectorTemp.ArcLen = cArcLen;
        SectorTemp.Orientation = cOrientation;
        SectorTemp.Depth = cDepth;
        SectorTemp.Shell = cThickness;
        SectorTemp.DamageType = cDamageType;
        SectorTemp.Source = cSource;
        this.cSectors.push(SectorTemp);
    }
    AddSector1(sector) {
        this.cSectors.push(sector);
    }
    RemoveSector(sector) {
        if (this.cSectors.indexOf(sector) > -1) {
            let arr = this.cSectors.filter(ele => ele != sector);
            this.cSectors = arr;
            return true;
        }
        return false;
    }
    RemoveSector1(index) {
        if (this.cSectors.length > index) {
            this.cSectors.splice(index, 1);
            return true;
        }
        return false;
    }
    ClearSectors() {
        this.cSectors = [];
    }
    GetExposedPocketsTypes() {
        let sectorsTemp = this.cSectors.filter(x => x.DamageType == Damage_1.DamageTypes.ExposedPocket);
        return sectorsTemp;
    }
    GetEnclosedPocketsTypes() {
        let sectorsTemp = this.cSectors.filter(x => x.DamageType == Damage_1.DamageTypes.EnclosedPocket);
        return sectorsTemp;
    }
    GetMechanicalDamagesTypes() {
        let chordsTemp = this.cChords.filter(x => x.DamageType == Damage_1.DamageTypes.MechanicalDamage);
        return chordsTemp;
    }
    GetHollowPocketTypes() {
        let sectorsTemp = this.cSectors.filter(x => x.DamageType == Damage_1.DamageTypes.HollowPocket);
        return sectorsTemp;
    }
    GetUnknownPoleTypes() {
        let unknownsTemp = this.cSectors.filter(x => x.DamageType == Damage_1.DamageTypes.Unknown);
        return unknownsTemp;
    }
    GetTotalDamageCount() {
        return this.cSectors.length + this.cChords.length;
    }
    CalculateStrength() {
        try {
            if (this.Calculating)
                return false;
            this.Calculating = true;
            this.ClearEverything();
            this.GetErrorMessages();
            if (this.ErrorMessages.filter(x => x.DamageType == Damage_1.DamageTypes.ShellRot).length > 0) {
                this.Calculating = false;
                return false;
            }
            for (let sec of this.cSectors)
                if (!this.ErrorMessages.some(x => x.GUID == sec.DamageGuid)) {
                    sec.Overlap = false;
                    this.Sectors.push(sec);
                }
            for (let ch of this.cChords)
                if (!this.ErrorMessages.some(x => x.GUID == ch.DamageGuid)) {
                    ch.Overlap = false;
                    this.Chords.push(ch);
                }
            this.Chords = this.Chords.filter(x => x.ArcLen > 1e-5);
            this.Chords = this.Chords.filter(x => this.OGRadius - x.ChordDepth(this.OGRadius) < this.SRRadius);
            this.Sectors = this.Sectors.filter(x => x.ArcLen > 1e-5 && x.Shell < x.Depth);
            this.Sectors = this.Sectors.filter(x => (this.OGRadius - x.Depth) < this.SRRadius);
            // #region Decay values
            // Pole Decay Location Index as follows:
            //  0 = Pole Decay in Longitudinal Direction (Line of Lead) //  1 = +45 Degrees (+Transverse Direction)
            //  2 = +90 Degrees (+Transverse Direction)                 //  3 = +135 Degrees (+Transverse Direction)
            //  4 = +180 Degrees (-Longitudinal Direction)              //  5 = +225 Degrees (or -135) Degrees (-Transverse Direction)
            //  6 = +270 Degrees (or -90) (-Transverse Direction)       //  7 = +315 Degrees (or -45) (-Transverse Direction) 
            // #endregion
            this.SetOverlappingSectors();
            this.SetOverlappingChords();
            this.OverlapExists = this.Chords.some(x => x.Overlap) || this.Sectors.some(x => x.Overlap);
            this.SetMOISector();
            this.SetMOIChord();
            this.RunOverlapGrid();
            this.SumTotalMOI();
            this.SolveForStrength();
            
            //RemainingStrength *= BucklingStrengthFactor();  // Round the final result down to be conservative.
            this.BucklingFactor = this.BucklingStrengthFactor();
            this.MinRemainingStrengthPrecise *= this.BucklingFactor;
            this.MaxRemainingStrengthPrecise *= this.BucklingFactor;
            this.RemainingStrengthAtLoLPrecise *= this.BucklingFactor;
            // Check if buckling applies
            if (this.Sectors.length == 0 && this.Chords.length == 0) {
                this.EffectiveCircumferenceAtLoL = parseFloat(this.cShellRotThisCycle.toFixed(2));
                this.MinEffectiveCircumference = parseFloat(this.cShellRotThisCycle.toFixed(2));
                this.EffectiveCircumferenceAtLoLPrecise = this.cShellRotThisCycle;
                this.MinEffectiveCircumferencePrecise = this.cShellRotThisCycle;
            }
            //Calculate Effective Circumference (including buckling adjustment
            else {
                this.EffectiveCircumferenceAtLoL = parseFloat((this.cOriginalCircumference * Math.pow((this.RemainingStrengthAtLoL / 100), 0.333333333333333)).toFixed(2));
                this.MinEffectiveCircumference = parseFloat((this.cOriginalCircumference * Math.pow((this.MinRemainingStrength / 100), 0.333333333333333)).toFixed(2));
                this.EffectiveCircumferenceAtLoLPrecise = this.cOriginalCircumference * Math.pow((this.RemainingStrengthAtLoLPrecise / 100), 0.333333333333333);
                this.MinEffectiveCircumferencePrecise = this.cOriginalCircumference * Math.pow((this.MinRemainingStrengthPrecise / 100), 0.333333333333333);
            }
            this.Calculating = false;
            return true;
        }
        catch (_a) {
            let e = new Error_1.Error();
            e.ErrorMessage = "Error calculating strength.";
            e.DamageType = Damage_1.DamageTypes.Unknown;
            this.ErrorMessages.push(e);
        }
        this.Calculating = false;
        return false;
    }
    ClearEverything() {
        this.Sectors = [];
        this.Chords = [];
        this.SectorsCGx = this.SectorsCGy = this.SectorsIxx = this.SectorsIyy = this.SectorsIxy = this.SectorsIxxCG = this.SectorsIyyCG = this.SectorsIxyCG = this.SectorsArea = 0;
        this.ChordsCGx = this.ChordsCGy = this.ChordsIxx = this.ChordsIyy = this.ChordsIxy = this.ChordsIxxCG = this.ChordsIyyCG = this.ChordsIxyCG = this.ChordsArea = 0;
        this.OverlapCGx = this.OverlapCGy = this.OverlapIxx = this.OverlapIyy = this.OverlapIxy = this.OverlapArea = 0;
        this.IxxCG = this.IyyCG = this.IxyCG = this.CGx = this.CGy = 0;
        this.kVal = 0;
        this.GridSquareRatio = 1.0;
        this.MaxRemainingStrengthPrecise = this.MinRemainingStrengthPrecise = this.RemainingStrengthAtLoLPrecise = 0.0;
        this.MaxRemDegBend = this.MinRemDegBend = 0;
        this.BucklingFactor = 1.0;
        this.EffectiveCircumferenceAtLoL = this.MinEffectiveCircumference = 0.0;
        for (let sec of this.Sectors)
            sec.Overlap = false;
        for (let ch of this.Chords)
            ch.Overlap = false;
    }
    Copy() {
        // Create copies of all of the damages
        let newSectorList = new Array();
        let newChordList = new Array();
        for (let sec of this.cSectors) {
            let newSec = new Sector_1.Sector();
            newSec.Orientation = sec.Orientation;
            newSec.ArcLen = sec.ArcLen;
            newSec.Shell = sec.Shell;
            newSec.Depth = sec.Depth;
            newSec.DamageType = sec.DamageType;
            newSec.Source = sec.Source;
            newSectorList.push(newSec);
        }
        for (let chr of this.cChords) {
            let newChr = new Chord_1.Chord();
            newChr.Orientation = chr.Orientation;
            newChr.ArcLen = chr.ArcLen;
            newChr.DamageType = chr.DamageType;
            newChr.Source = chr.Source;
            newChordList.push(newChr);
        }
        let newInst = new StrengthCalcV4(this.cOriginalCircumference, this.cShellRotThisCycle, newSectorList, newChordList);
        newInst.CalculateStrength();
        return newInst;
    }
    Compare(pOther) {
        if (pOther == null)
            return false;
        if (pOther.cOriginalCircumference != this.cOriginalCircumference)
            return false;
        if (pOther.cShellRotThisCycle != this.cShellRotThisCycle)
            return false;
        // Damage counts
        if (this.cSectors.length != pOther.cSectors.length ||
            this.cChords.length != pOther.cChords.length)
            return false;
        // Compare the actual damages
        for (let sec of this.cSectors) {
            let match = false;
            for (let other of pOther.cSectors)
                if (other.Summary == sec.Summary) {
                    match = true;
                    break;
                }
            // If none of the exposed pockets in the object we're comparing matched 
            // then return false. Something must be different.
            if (!match)
                return false;
        }
        for (let chr of this.cChords) {
            let match = false;
            for (let other of pOther.cChords)
                if (other.Summary == chr.Summary) {
                    match = true;
                    break;
                }
            if (!match)
                return false;
        }
        return true;
    }
    serializeSTRCALC() {
        // STRCalcRoot myDeserializedClass = JsonConvert.DeserializeObject<STRCalcRoot>(jsonstring); 
        let sc = new STRCALC_1.STRCALC();
        //Start with the basic members
        sc.Circumference = this.cOriginalCircumference;
        sc.ShellRot = this.cShellRotThisCycle;
        sc.EffectiveCircumference = this.EffectiveCircumferenceAtLoL;
        sc.RemainingStrength = this.RemainingStrengthAtLoL;
        // Get the list members
        var exps = this.GetExposedPocketsTypes();
        var encs = this.GetEnclosedPocketsTypes();
        var hlws = this.GetHollowPocketTypes();
        var unks = this.GetUnknownPoleTypes();
        if (exps.length > 0) {
            sc.EXPPOCKS = new Array(exps.length);
            for (let sec of exps) {
                let currentSec = new STRCALC_1.EXPPOCK();
                currentSec.ARCLEN = sec.ArcLen;
                currentSec.ORIENT = sec.Orientation;
                currentSec.SHELL = sec.Shell;
                currentSec.DEPTH = sec.Depth;
                currentSec.SOURCE = Damage_1.Damage.GetDescriptionSourceType(sec.Source);
                sc.EXPPOCKS.push(currentSec);
            }
        }
        if (encs.length > 0) {
            sc.ENCPOCKS = new Array(encs.length);
            for (let sec of encs) {
                let currentSec = new STRCALC_1.ENCPOCK();
                currentSec.ARCLEN = sec.ArcLen;
                currentSec.ORIENT = sec.Orientation;
                currentSec.SHELL = sec.Shell;
                currentSec.DEPTH = sec.Depth;
                currentSec.SOURCE = Damage_1.Damage.GetDescriptionSourceType(sec.Source);
                sc.ENCPOCKS.push(currentSec);
            }
        }
        if (hlws.length > 0) {
            sc.HLWPOCKS = new Array(hlws.length);
            for (let sec of hlws) {
                let currentSec = new STRCALC_1.HLWPOCK();
                currentSec.ARCLEN = sec.ArcLen;
                currentSec.ORIENT = sec.Orientation;
                currentSec.SHELL = sec.Shell;
                currentSec.DEPTH = sec.Depth;
                currentSec.SOURCE = Damage_1.Damage.GetDescriptionSourceType(sec.Source);
                sc.HLWPOCKS.push(currentSec);
            }
        }
        if (unks.length > 0) {
            sc.UNKPOCKS = new Array(unks.length);
            for (let sec of unks) {
                let currentSec = new STRCALC_1.UNKPOCK();
                currentSec.ARCLEN = sec.ArcLen;
                currentSec.ORIENT = sec.Orientation;
                currentSec.SHELL = sec.Shell;
                currentSec.DEPTH = sec.Depth;
                currentSec.SOURCE = Damage_1.Damage.GetDescriptionSourceType(sec.Source);
                sc.UNKPOCKS.push(currentSec);
            }
        }
        if (this.cChords.length > 0) {
            sc.MECHDMGS = new Array(this.cChords.length);
            for (let chrd of this.cChords) {
                let currentSec = new STRCALC_1.MECHDMG();
                currentSec.ARCLEN = chrd.ArcLen;
                currentSec.ORIENT = chrd.Orientation;
                currentSec.SOURCE = Damage_1.Damage.GetDescriptionSourceType(chrd.Source);
                sc.MECHDMGS.push(currentSec);
            }
        }
        //sc.EXPPOCKS = sc.EXPPOCKS.filter(item => item);
        //sc.HLWPOCKS = sc.HLWPOCKS.filter(item => item);
        return JSON.stringify(sc);
    }
    GetErrorMessages() {
        // clear the error messages
        this.ErrorMessages = [];
        this.cDecayPropertyList = [];
        if (this.cOriginalCircumference <= 11 && this.cOriginalCircumference != 0.0) {
            this.ErrorMessages.push(new Error_1.Error(null, "Groundline circumference must be greater than 11.", Damage_1.DamageTypes.ShellRot));
        }
        if (this.cOriginalCircumference < this.cShellRotThisCycle) {
            this.ErrorMessages.push(new Error_1.Error(null, "Shell rot circumference this cycle can't be greater than the original groundline circumference.", Damage_1.DamageTypes.ShellRot));
        }
        if (this.cOriginalCircumference == 0.0 || this.cShellRotThisCycle == 0.0)
            this.ErrorMessages.push(new Error_1.Error(null, "Shell rot circumference and groundline circumference must be greater than 0", Damage_1.DamageTypes.ShellRot));
        if (this.ErrorMessages.length > 0)
            return;
        let pocketStrings = new Array("Unknown", "Exposed Pocket", "Enclosed Pocket", "Hollow Pole", "Mechanical Damage");
        for (let sec of this.cSectors) {
            sec.HasAnError = false;
            if (sec.ArcLen < 0.125) {
                this.ErrorMessages.push(new Error_1.Error(sec.DamageGuid, pocketStrings[sec.DamageType] + " - Arc Length is too small", sec.DamageType));
                sec.HasAnError = true;
                continue;
            }
            if (sec.ArcLen > parseFloat(this.OGCirc.toFixed(2)) / 2.0) {
                this.ErrorMessages.push(new Error_1.Error(sec.DamageGuid, pocketStrings[sec.DamageType] + " - Arc Length is too large", sec.DamageType));
                sec.HasAnError = true;
                continue;
            }
            if (sec.Depth < 0.125) {
                this.ErrorMessages.push(new Error_1.Error(sec.DamageGuid, pocketStrings[sec.DamageType] + " - Depth is too small", sec.DamageType));
                sec.HasAnError = true;
                continue;
            }
            if (sec.Depth > parseFloat(this.OGRadius.toFixed(2))) {
                this.ErrorMessages.push(new Error_1.Error(sec.DamageGuid, pocketStrings[sec.DamageType] + " - Depth is too large", sec.DamageType));
                sec.HasAnError = true;
                continue;
            }
            if (sec.DamageType == Damage_1.DamageTypes.EnclosedPocket || sec.DamageType == Damage_1.DamageTypes.HollowPocket) {
                if (sec.Depth <= sec.Shell) {
                    this.ErrorMessages.push(new Error_1.Error(sec.DamageGuid, pocketStrings[sec.DamageType] + " - Shell should not exceed Depth", sec.DamageType));
                    sec.HasAnError = true;
                    continue;
                }
                if (sec.Shell > parseFloat(this.OGRadius.toFixed(2))) {
                    this.ErrorMessages.push(new Error_1.Error(sec.DamageGuid, pocketStrings[sec.DamageType] + " - Shell is too large", sec.DamageType));
                    sec.HasAnError = true;
                    continue;
                }
                if (sec.Shell < 0.125) {
                    this.ErrorMessages.push(new Error_1.Error(sec.DamageGuid, pocketStrings[sec.DamageType] + " - Shell is too small", sec.DamageType));
                    continue;
                }
            }
        }
        for (let ch of this.cChords) {
            ch.HasAnError = false;
            if (ch.ArcLen < 0.125) {
                this.ErrorMessages.push(new Error_1.Error(ch.DamageGuid, "Mechanical Damage - Arc Length is too small", ch.DamageType));
                ch.HasAnError = true;
                continue;
            }
            if (ch.ArcLen > parseFloat(this.OGCirc.toFixed(2)) / 2.0) {
                this.ErrorMessages.push(new Error_1.Error(ch.DamageGuid, "Mechanical Damage - Arc Length is too large", ch.DamageType));
                ch.HasAnError = true;
                continue;
            }
        }
    }
    SetOverlappingSectors() {
        for (let i = 0; i < this.Sectors.length; i++) {
            var sec = this.Sectors[i];
            var dMinO = sec.MinODeg(this.OGRadius) + 1; //in sector minimum angle
            var dMaxO = sec.MaxODeg(this.OGRadius) - 1; //in sector maximum angle
            var dMinO1 = (dMinO + 360); //deg sector minimum angle
            var dMaxO1 = (dMaxO + 360); //deg sector maximum angle
            var dMinO2 = (dMinO - 360); //deg sector minimum angle
            var dMaxO2 = (dMaxO - 360); //deg sector maximum angle
            if (!sec.Overlap)
                for (let k = 0; k < this.Sectors.length; k++) {
                    if (k == i)
                        continue;
                    var sec2 = this.Sectors[k];
                    var bSectorsOverlap = false;
                    if (sec.MaxR(this.OGRadius, this.SRRadius) > sec2.MinR(this.OGRadius, this.SRRadius))
                        if (sec.MinR(this.OGRadius, this.SRRadius) < sec2.MaxR(this.OGRadius, this.SRRadius)) {
                            var C1 = sec2.MinODeg(this.OGRadius); //deg sector minimum angle
                            var C2 = sec2.MaxODeg(this.OGRadius); //deg sector maximum angle
                            if ((C1 < dMaxO) && (C2 > dMinO))
                                bSectorsOverlap = true;
                            else if ((C1 < dMaxO1) && (C2 > dMinO1))
                                bSectorsOverlap = true;
                            else if ((C1 < dMaxO2) && (C2 > dMinO2))
                                bSectorsOverlap = true;
                        }
                    if (bSectorsOverlap) {
                        this.Sectors[i].Overlap = true;
                        this.Sectors[k].Overlap = true;
                    }
                }
            for (let k = 0; k < this.Chords.length; k++) {
                var ch = this.Chords[k];
                var bSectorsOverlap = false;
                if (sec.MaxR(this.OGRadius, this.SRRadius) > ch.MinR(this.OGRadius, this.SRRadius)) {
                    var C1 = ch.MinODeg2(this.OGRadius, this.SRRadius); //deg sector minimum angle
                    var C2 = ch.MaxODeg2(this.OGRadius, this.SRRadius); //deg sector maximum angle
                    if ((C1 < dMaxO) && (C2 > dMinO))
                        bSectorsOverlap = true;
                    else if ((C1 < dMaxO1) && (C2 > dMinO1))
                        bSectorsOverlap = true;
                    else if ((C1 < dMaxO2) && (C2 > dMinO2))
                        bSectorsOverlap = true;
                }
                if (bSectorsOverlap) {
                    sec.Overlap = true;
                    ch.Overlap = true;
                }
            }
        }
    }
    SetOverlappingChords() {
        for (let i = 0; i < this.Chords.length; i++) {
            var ch = this.Chords[i];
            var dMinO = ch.MinODeg2(this.OGRadius, this.SRRadius) + 1; //in sector minimum angle
            var dMaxO = ch.MaxODeg2(this.OGRadius, this.SRRadius) - 1; //in sector maximum angle
            var dMinO1 = (dMinO + 360); //deg sector minimum angle
            var dMaxO1 = (dMaxO + 360); //deg sector maximum angle
            var dMinO2 = (dMinO - 360); //deg sector minimum angle
            var dMaxO2 = (dMaxO - 360); //deg sector maximum angle
            if (!ch.Overlap)
                for (let k = 0; k < this.Chords.length; k++) {
                    if (k == i)
                        continue;
                    var ch2 = this.Chords[k];
                    var bChordsOverlap = false;
                    var C1 = ch2.MinODeg2(this.OGRadius, this.SRRadius); //deg sector minimum angle
                    var C2 = ch2.MaxODeg2(this.OGRadius, this.SRRadius); //deg sector maximum angle
                    if ((C1 < dMaxO) && (C2 > dMinO))
                        bChordsOverlap = true;
                    else if ((C1 < dMaxO1) && (C2 > dMinO1))
                        bChordsOverlap = true;
                    else if ((C1 < dMaxO2) && (C2 > dMinO2))
                        bChordsOverlap = true;
                    if (bChordsOverlap) {
                        ch.Overlap = true;
                        ch2.Overlap = true;
                    }
                }
        }
    }
    SetMOISector() {
        var k = 0;
        var SectorCGx = new Array();
        var SectorCGy = new Array();
        var SectorIxx = new Array();
        var SectorIxxCG = new Array();
        var SectorIyy = new Array();
        var SectorIyyCG = new Array();
        var SectorIxy = new Array();
        var SectorIxyCG = new Array();
        for (let sec of this.Sectors) 
        //    if (!sec.Overlap)
        {
            var dMaxR = sec.MaxR(this.OGRadius, this.SRRadius); //in sector maximum radius
            var dMinR = sec.MinR(this.OGRadius, this.SRRadius); //in sector minimum radius
            var DecayArea = sec.Area2(this.OGRadius, this.SRRadius);
            var SecMaxO = sec.MaxORad(this.OGRadius);
            var O = (SecMaxO - sec.OrientationRad); //rad Pocket Sector Half-Angle of Arc Length on Original Pole Surface
            var R = (dMaxR - dMinR) / dMaxR; //ratio of Pocket Sector Void Thickness / Pocket Sector Arc Outer Radius
            var CGx = 2.0 * dMaxR * Math.sin(O) / (3.0 * O) * (1.0 - R + 1.0 / (2.0 - R)); //in CGx of Sector Area Reduction from Pole Center
            var CGy = 0.0; //in CGy of Sector Area Reduction from Pole Center
            O = 2.0 * O; //rad Pocket Sector Angle of Arc Length on Shell Rot Surface
            var dMaxR4 = Math.pow(dMaxR, 4);
            var dMinR4 = Math.pow(dMinR, 4);
            var Ixx = (dMaxR4 - dMinR4) / 8.0 * (O - Math.sin(O)); //in^4 MOIxx Reduction about X Axis
            var IxxCG = Ixx - (DecayArea * CGy * CGy); //in^4 MOIxx Reduction about Sector CGy (centroidal axis)
            var Iyy = (dMaxR4 - dMinR4) / 8.0 * (O + Math.sin(O)); //in^4 MOIyy Reduction about Y Axis
            var IyyCG = Iyy - (DecayArea * CGx * CGx); //in^4 MOIyy Reduction about Sector CGx (centroidal axis)
            var Ixy = 0; //in^4 MOIxy Reduction about Origin
            var IxyCG = Ixy - (DecayArea * CGx * CGy); //in^4 MOIxy Reduction about Sector CG (centroidal axis)
            //Sector Properties when Circular Sector Centerline Angle = Actual Circular Chord Centerline Input Angle Location
            O = sec.Orientation / this.DRCON; //rad Circular Sector Centerline Angle Location
            SectorCGx.push(CGx * Math.cos(O) - CGy * Math.sin(O));
            SectorCGy.push(CGx * Math.sin(O) + CGy * Math.cos(O));
            CGx = SectorCGx[k];
            CGy = SectorCGy[k];
            O = 2 * O;
            SectorIxxCG.push(((IxxCG + IyyCG) + (IxxCG - IyyCG) * Math.cos(O)) / 2 + IxyCG * Math.sin(O)); //in^4 MOIxx Reduction about Sector CGy (centroidal axis)
            SectorIxx.push(SectorIxxCG[k] + (DecayArea * CGy * CGy)); //in^4 MOIxx Reduction about X Axis
            SectorIyyCG.push(((IxxCG + IyyCG) - (IxxCG - IyyCG) * Math.cos(O)) / 2 - IxyCG * Math.sin(O)); //in^4 MOIyy Reduction about Sector CGx (centroidal axis)
            SectorIyy.push(SectorIyyCG[k] + (DecayArea * CGx * CGx)); //in^4 MOIyy Reduction about Y Axis
            SectorIxyCG.push((IyyCG - IxxCG) * Math.sin(O) / 2 + IxyCG * Math.cos(O)); //in^4 MOIxy Reduction about Sector CG (centroidal axis)
            SectorIxy.push(SectorIxyCG[k] + (DecayArea * CGx * CGy)); //in^4 MOIxy Reduction about Origin
            k++;
        }
        this.SectorsCGx = this.SectorsCGy = this.SectorsIxx = this.SectorsIyy = this.SectorsIxy = this.SectorsIxxCG = this.SectorsIyyCG = this.SectorsIxyCG = 0;
        //Sum the properties for all sectors that do not overlap
        for (let i = 0; i < this.Sectors.length; i++) {
            var sec = this.Sectors[i];
            if (!this.Sectors[i].Overlap) {
                this.SectorsArea += sec.Area2(this.OGRadius, this.SRRadius);
                this.SectorsCGx += sec.Area2(this.OGRadius, this.SRRadius) * SectorCGx[i];
                this.SectorsCGy += sec.Area2(this.OGRadius, this.SRRadius) * SectorCGy[i];
                this.SectorsIxx += SectorIxx[i];
                this.SectorsIyy += SectorIyy[i];
                this.SectorsIxy += SectorIxy[i];
            }
        }
        if (this.SectorsArea > 0) {
            this.SectorsCGx = this.SectorsCGx / this.SectorsArea;
            this.SectorsCGy = this.SectorsCGy / this.SectorsArea;
            this.SectorsIxxCG = this.SectorsIxx - (this.SectorsArea * this.SectorsCGy * this.SectorsCGy);
            this.SectorsIyyCG = this.SectorsIyy - (this.SectorsArea * this.SectorsCGx * this.SectorsCGx);
            this.SectorsIxyCG = this.SectorsIxy - (this.SectorsArea * this.SectorsCGx * this.SectorsCGy);
        }
        else
            this.SectorsCGx = this.SectorsCGy = this.SectorsIxx = this.SectorsIyy = this.SectorsIxy = this.SectorsIxxCG = this.SectorsIyyCG = this.SectorsIxyCG = 0;
    }
    SetMOIChord() {
        var R = this.SRRadius;
        var R4 = Math.pow(R, 4);
        var k = 0;
        var ChordCGx = new Array();
        var ChordCGy = new Array();
        var ChordIxx = new Array();
        var ChordIxxCG = new Array();
        var ChordIyy = new Array();
        var ChordIyyCG = new Array();
        var ChordIxy = new Array();
        var ChordIxyCG = new Array();
        for (let ch of this.Chords) {
            //Chord Properties when Circular Chord Centerline Angle = 0º (Principal Axis of Chord)
            var O = (ch.MaxORad2(this.OGRadius, this.SRRadius) - ch.MinORad2(this.OGRadius, this.SRRadius)); //rad Circular Chord Angle of Arc Length on Pole Shell Rot Surface
            //DecayArea = R * R / 2 * (O - Sin(O))
            var DecayArea = ch.Area2(this.OGRadius, this.SRRadius);
            var CGx = 4.0 / 3.0 * R * Math.pow(Math.sin(O / 2), 3) / (O - Math.sin(O)); //in CGx of Chord Area Reduction from Pole Center
            var CGy = 0.0; //in CGy of Chord Area Reduction from Pole Center
            var Ixx = R4 / 8.0 * (O - Math.sin(O) - 2.0 / 3.0 * Math.sin(O) * Math.pow(Math.sin(O / 2), 2)); //in^4 MOIxx Reduction about X Axis
            var IxxCG = Ixx - (DecayArea * CGy * CGy); //in^4 MOIxx Reduction about Chord CGy (centroidal axis)
            var Iyy = R4 / 8.0 * (O - Math.sin(O) + 2 * Math.sin(O) * Math.pow(Math.sin(O / 2), 2)); //in^4 MOIyy Reduction about Y Axis
            var IyyCG = Iyy - (DecayArea * CGx * CGx); //in^4 MOIyy Reduction about Chord CGx (centroidal axis)
            var Ixy = 0; //in^4 MOIxy Reduction about Origin
            var IxyCG = Ixy - (DecayArea * CGx * CGy); //in^4 MOIxy Reduction about Chord CG (centroidal axis)
            //Chord Properties when Circular Chord Centerline Angle = Actual Circular Chord Centerline Input Angle Location
            O = ch.Orientation / this.DRCON; //rad Circular Chord Centerline Angle Location
            ChordCGx.push(CGx * Math.cos(O) - CGy * Math.sin(O));
            ChordCGy.push(CGx * Math.sin(O) + CGy * Math.cos(O));
            CGx = ChordCGx[k];
            CGy = ChordCGy[k];
            O = 2.0 * O;
            ChordIxxCG.push(((IxxCG + IyyCG) + (IxxCG - IyyCG) * Math.cos(O)) / 2 + IxyCG * Math.sin(O)); //in^4 MOIxx Reduction about Chord CGy (centroidal axis)
            ChordIxx.push(ChordIxxCG[k] + (DecayArea * CGy * CGy)); //in^4 MOIxx Reduction about X Axis
            ChordIyyCG.push(((IxxCG + IyyCG) - (IxxCG - IyyCG) * Math.cos(O)) / 2 - IxyCG * Math.sin(O)); //in^4 MOIyy Reduction about Chord CGx (centroidal axis)
            ChordIyy.push(ChordIyyCG[k] + (DecayArea * CGx * CGx)); //in^4 MOIyy Reduction about Y Axis
            ChordIxyCG.push((IyyCG - IxxCG) * Math.sin(O) / 2 + IxyCG * Math.cos(O)); //in^4 MOIxy Reduction about Chord CG (centroidal axis)
            ChordIxy.push(ChordIxyCG[k] + (DecayArea * CGx * CGy)); //in^4 MOIxy Reduction about Origin
            k++;
        }
        //Sum the properties for all Chords that do not overlap
        for (let i = 0; i < this.Chords.length; i++) {
            var ch = this.Chords[i];
            if (!this.Chords[i].Overlap) {
                this.ChordsArea += ch.Area2(this.OGRadius, this.SRRadius);
                this.ChordsCGx += ch.Area2(this.OGRadius, this.SRRadius) * ChordCGx[i];
                this.ChordsCGy += ch.Area2(this.OGRadius, this.SRRadius) * ChordCGy[i];
                this.ChordsIxx += ChordIxx[i];
                this.ChordsIyy += ChordIyy[i];
                this.ChordsIxy += ChordIxy[i];
            }
        }
        if (this.ChordsArea > 0) {
            this.ChordsCGx = this.ChordsCGx / this.ChordsArea;
            this.ChordsCGy = this.ChordsCGy / this.ChordsArea;
            this.ChordsIxxCG = this.ChordsIxx - (this.ChordsArea * this.ChordsCGy * this.ChordsCGy);
            this.ChordsIyyCG = this.ChordsIyy - (this.ChordsArea * this.ChordsCGx * this.ChordsCGx);
            this.ChordsIxyCG = this.ChordsIxy - (this.ChordsArea * this.ChordsCGx * this.ChordsCGy);
        }
        else
            this.ChordsCGx = this.ChordsCGy = this.ChordsIxx = this.ChordsIyy = this.ChordsIxy = this.ChordsIxxCG = this.ChordsIyyCG = this.ChordsIxyCG = 0;
    }
    RunOverlapGrid() {
        this.OverlapCGx = this.OverlapCGy = this.OverlapIxx = this.OverlapIyy = this.OverlapIxy = this.OverlapArea = 0;
        this.Cr = [...Array(this.n + 1)].map(e => Array(this.n + 1));
        this.Co = [...Array(this.n + 1)].map(e => Array(this.n + 1));
        var n1 = this.n + 1;
        var n2 = (this.n / 2);
        var dX = (2 * this.SRRadius) / this.n; //width of square in grid
        var dY = dX; //height of a square in grid
        var Area = dX * dY; //area of a square in grid
        var MOIxx = dX * Math.pow(dY, 3) / 12; //moment of inertia of a square in the grid about square center, x axis
        var MOIyy = dY * Math.pow(dX, 3) / 12; //moment of inertia of a square in the grid about square center, y axis
        var MOIxy = 0.0; //product of inertia of a square in the grid about square center
        var DRCON = 180.0 / Math.PI;
        var Coord1 = new Array(this.n + 1);
        var Coord2 = new Array(this.n + 1);
        var R = 0;
        var k = 0;
        var nSectors = this.Sectors.length;
        var bSectorsOverlap = this.Sectors.some(x => x.Overlap);
        var nChords = this.Chords.length;
        var bChordsOverlap = this.Chords.some(x => x.Overlap);
        if (!bSectorsOverlap && !bChordsOverlap)
            return;
        //Determine the center coordinate of each square in the n x n grid for the rows (and columns)
        var X = -this.SRRadius - (dX / 2);
        for (let i = 1; i <= this.n; i++) {
            var Y = (X + (dX * i));
            Coord1[i] = Y; //X and Y coordinate of the center of each square
            Coord2[i] = (Y * Y); //X and Y coordinate squared
        }
        //Determine the polar coordinates of each square center in the n x n grid
        //Determine the polar coordinates of the diagonal squares center in the first quadrant
        var nn = (n2 + 1); //Always a whole number since n is even.  One row above pole center.
        for (let i = nn; i <= this.n; i++) //rows and columns on diagonal in the first quadrant
         {
            this.Cr[i][i] = (Math.sqrt(2) * Coord1[i]); //sqr(2) times X coordinate
            this.Co[i][i] = 45; //deg angle of diagonal
        }
        //Determine the polar coordinates of the off-diagonal squares center in the first quadrant
        for (let i = nn; i <= (this.n - 1); i++) //rows
         {
            var Y = Coord1[i];
            var Y2 = Coord2[i];
            for (let j = (i + 1); j <= this.n; j++) //columns
             {
                R = Math.sqrt(Coord2[j] + Y2); //radius of square center
                var O = Math.atan(Y / Coord1[j]) * DRCON; // angle O is always between 0º and 45º
                this.Cr[i][j] = R;
                this.Co[i][j] = O;
                this.Cr[j][i] = R; //symmetry within 1st quadrant about 45º axis
                this.Co[j][i] = (90 - O); //symmetry within 1st quadrant about 45º axis
            }
        }
        //Set the polar coordinates of the 2nd, 3rd and 4th quadrants based on the 1st quadrant coordinate values
        for (let i = nn; i <= this.n; i++) //rows
         {
            k = (n1 - i);
            for (let j = nn; j <= this.n; j++) //columns
             {
                R = this.Cr[i][j];
                var O = this.Co[i][j];
                var m = (n1 - j);
                this.Cr[i][m] = R;
                this.Co[i][m] = (180 - O); //deg 2nd quadrant using symmetry about Y axis
                this.Cr[k][m] = R;
                this.Co[k][m] = (180 + O); //deg 3rd quadrant 180º from 1st quadrant
                this.Cr[k][j] = R;
                this.Co[k][j] = (360 - O); //deg 4th quadrant using symmetry about X axis
            }
        }
        var iRowMin = n1;
        var iRowMax = 0;
        var iRowMinColumn = new Array(this.n + 1).fill(0);
        var iRowMaxColumn = new Array(this.n + 1).fill(0);
        let bGrid = [...Array(this.n + 1).fill(false)].map(e => Array(this.n + 1).fill(false));
        //var bGrid = [...Array((this.n + 1)*(this.n + 1)).fill(false)].map(e => Array(this.n + 1).fill(false));
        if (nSectors > 0 && bSectorsOverlap)
            for (let sec of this.Sectors)
                if (sec.Overlap) {
                    var dMinR = sec.MinR(this.OGRadius, this.SRRadius); // in sector minimum radius
                    var dMinR2 = dMinR * dMinR; // in^2 sector minimum radius squared
                    var dMaxR = sec.MaxR(this.OGRadius, this.SRRadius); // in sector maximum radius
                    var dMaxR2 = dMaxR * dMaxR; // in^2 sector maximum radius squared
                    var dMinO = parseFloat((sec.MinODeg(this.OGRadius)).toFixed(6)); // deg sector minimum angle
                    var dMaxO = parseFloat((sec.MaxODeg(this.OGRadius)).toFixed(6)); // deg sector maximum angle
                    var dMinO1 = dMinO + 360; // deg sector minimum angle
                    var dMaxO1 = dMaxO + 360; // deg sector maximum angle
                    var dMinO2 = dMinO - 360; // deg sector minimum angle
                    var dMaxO2 = dMaxO - 360; // deg sector maximum angle
                    var Y = 0, Y1 = 0, X1 = 0, X2 = 0;
                    if (dMinO <= -90 && dMaxO >= -90 || dMinO1 <= -90 && dMaxO1 >= -90 || dMinO2 <= -90 && dMaxO2 >= -90){
                        Y = -dMaxR; // minimum Y
                    }
                    else // find minimum Y from sector end points
                     {
                        Y = dMinR * Math.sin(dMinO / DRCON);
                        Y1 = dMaxR * Math.sin(dMinO / DRCON);
                        if (Y1 < Y){
                            Y = Y1;
                        }
                        Y1 = dMinR * Math.sin(dMaxO / DRCON);
                        if (Y1 < Y){
                            Y = Y1;
                        }
                        Y1 = dMaxR * Math.sin(dMaxO / DRCON);
                        if (Y1 < Y){
                            Y = Y1;
                        }
                    }
                    var iMinRow = parseInt(((this.SRRadius + Y) / dY + 0.5).toFixed());
                    if (iMinRow < 1){
                        iMinRow = 1;
                    }
                    if (dMinO <= 90 && dMaxO >= 90 || dMinO1 <= 90 && dMaxO1 >= 90 || dMinO2 <= 90 && dMaxO2 >= 90){
                        Y = dMaxR; // maximum Y
                    }
                    else // find maximum Y from sector end points
                     {
                        Y = dMinR * Math.sin(dMinO / DRCON);
                        Y1 = dMaxR * Math.sin(dMinO / DRCON);
                        if (Y1 > Y){
                            Y = Y1;
                        }
                        Y1 = dMinR * Math.sin(dMaxO / DRCON);
                        if (Y1 > Y){
                            Y = Y1;
                        }
                        Y1 = dMaxR * Math.sin(dMaxO / DRCON);
                        if (Y1 > Y){
                            Y = Y1;
                        }
                    }
                    var iMaxRow = parseInt(((this.SRRadius + Y) / dY + 1.5).toFixed());
                    if (iMaxRow > this.n){
                        iMaxRow = this.n;
                    }
                    var bQuadrant23 = false; // sector is in 2nd and 3rd quadrants
                    var bQuadrant41 = false; // sector is in 4th and 1st quadrants
                    if (iMinRow < iMaxRow) {
                        if (dMinO >= 0 && dMaxO <= 180 || dMinO1 >= 0 && dMaxO1 <= 180 || dMinO2 >= 0 && dMaxO2 <= 180) // sector is in 1st and 2nd quadrants
                         {
                            if (iMinRow < nn){
                                iMinRow = nn;
                            }
                        }
                        else if (dMinO >= 180 && dMaxO <= 360 || dMinO1 >= 180 && dMaxO1 <= 360 || dMinO2 >= 180 && dMaxO2 <= 360) // sector is in 3rd and 4th quadrants
                            if (iMaxRow > n2){
                                iMaxRow = n2;
                            }
                        if (dMinO >= 90 && dMaxO <= 270 || dMinO1 >= 90 && dMaxO1 <= 270 || dMinO2 >= 90 && dMaxO2 <= 270) // sector is in 2nd and 3rd quadrants
                         {
                            bQuadrant23 = true;
                            X1 = dMinR * Math.cos(dMinO / DRCON);
                            X2 = dMinR * Math.cos(dMaxO / DRCON);
                            if (X2 < X1){
                                X2 = X1; // X2 is the greater
                            }
                        }
                        else if (dMinO >= -90 && dMaxO <= 90 || dMinO1 >= -90 && dMaxO1 <= 90 || dMinO2 >= -90 && dMaxO2 <= 90) // sector is in 4th and 1st quadrants
                         {
                            bQuadrant41 = true;
                            X1 = dMinR * Math.cos(dMinO / DRCON);
                            X2 = dMinR * Math.cos(dMaxO / DRCON);
                            if (X2 < X1){
                                X1 = X2; // X1 is the lesser
                            }
                        }
                        // For i = 1 To n Step 1  'rows
                        for (let i = iMinRow; i <= iMaxRow; i++) // rows
                         {
                            if (iRowMinColumn[i] == 0 || iRowMinColumn[i] == undefined){
                                iRowMinColumn[i] = n1; // n1 = n + 1
                            }
                            
                            if (dMaxR2 >= Coord2[i]) {
                                // Determine column range based on a circle of sector maximum diameter and current row
                                X = Math.sqrt(dMaxR2 - Coord2[i]);
                                var iMinCol = parseInt(((this.SRRadius - X) / dX + 0.5).toString());
                                if (iMinCol < 1){
                                    iMinCol = 1;
                                }
                                var iMaxCol = n1 - iMinCol;
                                if (bQuadrant23) // sector is in 2nd and 3rd quadrants
                                 {
                                    X = 0;
                                    if (dMinR2 > Coord2[i]){
                                        X = -Math.sqrt(dMinR2 - Coord2[i]);
                                    }
                                    if (X > X2){
                                        X = X2; // X is the lesser and cannot be greater than the greater endpoint
                                    }
                                    iMaxCol = parseInt(((this.SRRadius + X) / dX + 1.5).toString());
                                    if (iMaxCol > n2){
                                        iMaxCol = n2; // n2 = n/2
                                    }
                                }
                                else if (bQuadrant41) // sector is in 4th and 1st quadrants
                                 {
                                    X = 0;
                                    if (dMinR2 > Coord2[i]){
                                        X = Math.sqrt(dMinR2 - Coord2[i]);
                                    }
                                    if (X < X1){
                                        X = X1; // X is the greater and cannot be less than the lesser endpoint
                                    }
                                    iMinCol = parseInt(((this.SRRadius + X) / dX + 0.5).toString());
                                    if (iMinCol < nn){
                                        iMinCol = nn; // nn = n/2 + 1
                                    }
                                }
                                var m = 0;
                                for (let j = iMinCol; j <= iMaxCol; j++){ // column range within circle for each row
                                    if (!bGrid[i][j]) // bGrid value is already True
                                     {
                                        R = this.Cr[i][j]; // in radius to grid point row i, column j
                                        if ((parseFloat((R - dMinR).toFixed(6)) >= 0) && (parseFloat((R - dMaxR).toFixed(6)) <= 0)) // the Square center radius is in the range of the sector decay radii
                                         {
                                            var O = parseFloat((this.Co[i][j]).toFixed(6));
                                            if ((O >= dMinO && O <= dMaxO) || (O >= dMinO1 && O <= dMaxO1) || (O >= dMinO2 && O <= dMaxO2)) {
                                                bGrid[i][j] = true; // the Square center in the n x n grid is within a sector decay area
                                                if (iRowMinColumn[i] > j){
                                                    iRowMinColumn[i] = j;
                                                }  
                                                // If (iRowMaxColumn(i) < j) Then iRowMaxColumn(i) = j
                                                m = j; // save last column number
                                            }
                                        }
                                    }
                                }
                                // If iRowMaxColumn(i) > 0 Then
                                if (m > 0) {
                                    if (iRowMaxColumn[i] < m || iRowMaxColumn[i] == undefined){
                                        iRowMaxColumn[i] = m;
                                    }
                                    if (iRowMin > i){
                                        iRowMin = i; // for all sectors of decay
                                    }
                                    if (iRowMax < i){
                                        iRowMax = i; // for all sectors of decay
                                    }
                                }
                            }
                        }
                    }
                }
        // Set Grid Squares within Chords with overlap and with damage to True; bGrid(i, j) = True
        if (nChords > 0 && bChordsOverlap) {
            var dMaxR = this.SRRadius; // in Chord maximum radius
            var dMaxR2 = dMaxR * dMaxR; // in^2 Chord maximum radius squared
            for (let ch of this.Chords)
                if (ch.Overlap) {
                    var dMinR = ch.MinR(this.OGRadius, this.SRRadius); // Radial Distance from Pole Center to Circular Chord Depth
                    var O = ch.Orientation; // deg circular Chord centerline angle location (could be any angle)
                    var dMinO = ch.MinODeg2(this.OGRadius, this.SRRadius); // deg Chord minimum angle on Pole Shell Rot Surface
                    var dMaxO = ch.MaxODeg2(this.OGRadius, this.SRRadius); // deg Chord maximum angle on Pole Shell Rot Surface
                    var dMinO1 = dMinO + 360; // deg Chord minimum angle on Pole Shell Rot Surface
                    var dMaxO1 = dMaxO + 360; // deg Chord maximum angle on Pole Shell Rot Surface
                    var dMinO2 = dMinO - 360; // deg Chord minimum angle on Pole Shell Rot Surface
                    var dMaxO2 = dMaxO - 360; // deg Chord maximum angle on Pole Shell Rot Surface
                    var iMinRow = 1;
                    var iMaxRow = this.n;
                    var SinChord = 0.0;
                    if (O != 0 && Math.abs(O) != 180 && Math.abs(O) != 360)
                        SinChord = Math.sin(O / DRCON); // circular Chord centerline angle Math.Sine
                    var CosChord = 0.0;
                    if (Math.abs(O) != 90 && Math.abs(O) != 270)
                        CosChord = Math.cos(O / DRCON); // circular Chord centerline angle Math.CoMath.Sine
                    var TanChord = 0.0;
                    if (SinChord != 0 && CosChord != 0)
                        TanChord = SinChord / CosChord;
                    // Determine the range of rows in the grid to scan for the Chord
                    var Y1 = this.SRRadius * Math.sin(dMinO / DRCON); // Y of Chord atart intersection with shell rot radius
                    var Y2 = this.SRRadius * Math.sin(dMaxO / DRCON); // Y of Chord end intersection with shell rot radius
                    var Y = 0;
                    if (SinChord > 0) // circular Chord centerline angle > 0º and < 180º
                     {
                        Y = Y1 < Y2 ? Y1 : Y2; // Y = lesser of Y1 and Y2
                        iMinRow = parseInt(((this.SRRadius + Y) / dY + 0.5).toString());
                        if (Math.cos(dMinO / DRCON) > 0 && Math.cos(dMaxO / DRCON) < 0) // +90º included
                            iMaxRow = this.n;
                        else {
                            Y = Y1 < Y2 ? Y2 : Y1; // Y = greater of Y1 and Y2
                            iMaxRow = parseInt(((this.SRRadius + Y) / dY + 1.5).toString());
                        }
                    }
                    else if (SinChord < 0) // circular Chord centerline angle > 180º and < 360º
                     {
                        Y = Y1 > Y2 ? Y1 : Y2; // Y = greater of Y1 and Y2
                        iMaxRow = parseInt(((this.SRRadius + Y) / dY + 1.5).toString());
                        if (Math.cos(dMinO / DRCON) < 0 && Math.cos(dMaxO / DRCON) > 0) // -90º included
                            iMinRow = 1;
                        else {
                            Y = Y1 > Y2 ? Y2 : Y1; // Y = lesser of Y1 and Y2
                            iMinRow = parseInt(((this.SRRadius + Y) / dY + 0.5).toString());
                        }
                    }
                    else // Math.SinChord = 0; circular Chord centerline angle = 0º or = ±180º or = ±360º
                     {
                        Y = Y1 < Y2 ? Y1 : Y2; // Y = lesser of Y1 and Y2
                        iMinRow = parseInt(((this.SRRadius + Y) / dY + 0.5).toString());
                        Y = Y1 < Y2 ? Y2 : Y1; // Y = greater of Y1 and Y2
                        iMaxRow = parseInt(((this.SRRadius + Y) / dY + 1.5).toString());
                    }
                    // Check to make sure that the row range is within the pole limits
                    if (iMinRow < 1)
                        iMinRow = 1;
                    if (iMaxRow < 1)
                        iMaxRow = 1;
                    if (iMinRow > this.n)
                        iMinRow = this.n;
                    if (iMaxRow > this.n)
                        iMaxRow = this.n;
                    // Scan the range of rows in the grid
                    var D1 = this.SRRadius + dMinR * CosChord; // X grid distance to Chord dMinR on Chord CL
                    var D2 = dMinR * SinChord; // Y distance from origin to Chord dMinR on Chord CL
                    for (let i = iMinRow; i <= iMaxRow; i++) // rows
                     {
                        if (iRowMinColumn[i] == 0 || iRowMinColumn[i] == undefined)
                            iRowMinColumn[i] = n1;
                        if (dMaxR2 > Coord2[i]) {
                            var iMinCol = 0;
                            var iMaxCol = 0;
                            // Determine the range of columns in the grid current row to scan for the Chord
                            X = Math.sqrt(dMaxR2 - Coord2[i]); // X coordinate on ShellRotPoleRadius for current row
                            var p = parseInt(((this.SRRadius - X) / dX + 0.5).toString()); // minimum column for current row based on ShellRotPoleRadius
                            if (p < 1)
                                p = 1;
                            var m = n1 - p; // maximum column for current row based on ShellRotPoleRadius
                            if (CosChord > 0) // circular Chord centerline angle > -90º and < 90º
                             {
                                var C2 = Coord1[i] - D2; // Y distance from Chord centerline dMinR point to grid row i
                                var C1 = -C2 * TanChord; // X distance from Chord centerline dMinR point for Y distance
                                iMinCol = parseInt(((D1 + C1) / dX + 0.5).toString());
                                iMaxCol = m;
                                if (iMinCol > m)
                                    iMinCol = m;
                            }
                            else if (CosChord < 0) // circular Chord centerline angle > 90º and < 270º or < -90º and > -270º
                             {
                                iMinCol = p;
                                var C2 = Coord1[i] - D2; // Y distance from Chord centerline dMinR point to grid row i
                                var C1 = -C2 * TanChord; // X distance from Chord centerline dMinR point for Y distance
                                iMaxCol = parseInt(((D1 + C1) / dX + 1.5).toString());
                                if (iMaxCol < p)
                                    iMaxCol = p;
                            }
                            else // Math.CosChord = 0; circular Chord centerline angle = ±90º or = ±270º
                             {
                                iMinCol = p;
                                iMaxCol = m;
                            }
                            // Check to make sure that the column range is within the pole limits
                            if (iMinCol < p)
                                iMinCol = p;
                            if (iMaxCol < p)
                                iMaxCol = p;
                            if (iMinCol > m)
                                iMinCol = m;
                            if (iMaxCol > m)
                                iMaxCol = m;
                            // Scan the range of columns in the grid
                            var CoordinateY = SinChord * Coord1[i];
                            m = 0;
                            for (let j = iMinCol; j <= iMaxCol; j++) // column range within circle for each row
                                if (!bGrid[i][j]) // bGrid value is already True
                                 {
                                    R = this.Cr[i][j];
                                    if ((parseFloat((R - dMinR).toFixed(6)) >= 0) && (parseFloat((R - dMaxR).toFixed(6)) <= 0)) // the radius of the Square center is in the range of the Chord damage radii
                                     {
                                        O = parseFloat((this.Co[i][j]).toFixed(6));
                                        if ((O >= dMinO && O <= dMaxO) || (O >= dMinO1 && O <= dMaxO1) || (O >= dMinO2 && O <= dMaxO2)) {
                                            // Y = R * Math.Cos((ChordO(k) - O) / DRCON)
                                            // Y = (Math.CosChord * Coordinate(j) + Math.SinChord * Coordinate(i))
                                            Y = CosChord * Coord1[j] + CoordinateY; // Projection of the vector to the square center on ChordO(nChords)
                                            if (parseFloat((Y - dMinR).toFixed(6)) >= 0) {
                                                bGrid[i][j] = true; // the Square center in the n x n grid is within a Chord damage area
                                                if (iRowMinColumn[i] > j)
                                                    iRowMinColumn[i] = j;
                                                // If (iRowMaxColumn(i) < j) Then iRowMaxColumn(i) = j
                                                m = j; // save last column number
                                            }
                                        }
                                    }
                                }
                            // If iRowMaxColumn(i) > 0 Then
                            if (m > 0) {
                                if (iRowMaxColumn[i] < m  || iRowMaxColumn[i] == undefined)
                                    iRowMaxColumn[i] = m;
                                if (iRowMin > i)
                                    iRowMin = i; // for all areas of decay or damage
                                if (iRowMax < i)
                                    iRowMax = i; // for all areas of decay or damage
                            }
                        }
                    }
                }
        }
        k = 0;
        if (iRowMin < n1) //at least one row in the n x n grid has decay or damage
         {
            //iRowMin = 240
            //For i = 1 To n Step 1  //all rows
            for (let i = iRowMin; i <= iRowMax; i++) //row range having decay or damage
             {
                var Y = Coord1[i]; //Y coordinate value for square centers in row i of grid
                var Y2 = Coord2[i]; //Y coordinate value squared for square centers in row i of grid
                //For j = 1 To n Step 1          //all columns
                //console.log(iRowMinColumn[i]);
                for (let j = iRowMinColumn[i]; j <= iRowMaxColumn[i]; j++) //column range having decay or damage for each row in row range having decay or damage
                {    
                    if (bGrid[i][j]) //the square center in the n x n grid is within a decayed or damaged sector area
                     {
                        k++; //counter for the number of squares having decay or damage
                        X = Coord1[j]; //X coordinate value for square centers in column j of the grid
                        this.OverlapCGx = this.OverlapCGx + X;
                        this.OverlapCGy = this.OverlapCGy + Y;
                        //OverlapIxx = OverlapIxx + (Y * Y)
                        this.OverlapIxx = this.OverlapIxx + Y2;
                        //OverlapIyy = OverlapIyy + (X * X)
                        this.OverlapIyy = this.OverlapIyy + Coord2[j];
                        this.OverlapIxy = this.OverlapIxy + (X * Y);
                    }
                }
            }
            this.kVal = k;
            this.GridSquareRatio = (k / (this.n * this.n)); //ratio of grid squares with decay or damage to total squares in the n x n grid
            //Properties of the grid Overlaped area about the origin (original pole center)
            this.OverlapArea = Area * k; //Area = area of a square in the grid
            
            this.OverlapCGx = this.OverlapCGx / k; //average of the X values
            this.OverlapCGy = this.OverlapCGy / k; //average of the Y values
            this.OverlapIxx = ((MOIxx * k) + (Area * this.OverlapIxx));
            this.OverlapIyy = ((MOIyy * k) + (Area * this.OverlapIyy));
            this.OverlapIxy = ((MOIxy * k) + (Area * this.OverlapIxy));
        }
    }
    SumTotalMOI() {
        var TotalArea = this.OverlapArea + this.SectorsArea + this.ChordsArea; //decay area not including shell rot area
        var R = (TotalArea / this.SRArea); //ratio of area with decay or damage to total area in the n x n grid
        var TotalCGx = 0;
        var TotalCGy = 0;
        var TotalIxx = 0;
        var TotalIyy = 0;
        var TotalIxy = 0;
        var TotalIxxCG = 0;
        var TotalIyyCG = 0;
        var TotalIxyCG = 0;
        if (TotalArea > 0) {
            TotalCGx = (this.OverlapArea * this.OverlapCGx + this.SectorsArea * this.SectorsCGx + this.ChordsArea * this.ChordsCGx) / TotalArea;
            TotalCGy = (this.OverlapArea * this.OverlapCGy + this.SectorsArea * this.SectorsCGy + this.ChordsArea * this.ChordsCGy) / TotalArea;
            TotalIxx = (this.OverlapIxx + this.SectorsIxx + this.ChordsIxx);
            TotalIyy = (this.OverlapIyy + this.SectorsIyy + this.ChordsIyy);
            TotalIxy = (this.OverlapIxy + this.SectorsIxy + this.ChordsIxy);
            
            TotalIxxCG = TotalIxx - (TotalArea * TotalCGy * TotalCGy);
            TotalIyyCG = TotalIyy - (TotalArea * TotalCGx * TotalCGx);
            TotalIxyCG = TotalIxy - (TotalArea * TotalCGx * TotalCGy);
        }
        
        var TotalDecayArea = (TotalArea + this.PSRArea);
        this.RemainingPoleArea = (this.OGArea - TotalDecayArea);
        this.CGx = 0; //CGx of Area Reduction from Pole Center including shell rot, sectors and segments
        this.CGy = 0; //CGy of Area Reduction from Pole Center including shell rot, sectors and segments
        
        
        if (this.RemainingPoleArea > 0) {
            this.CGx = -TotalArea * TotalCGx / this.RemainingPoleArea; // CGx of Area Reduction from Pole Center including shell rot, sectors and segments
            this.CGy = -TotalArea * TotalCGy / this.RemainingPoleArea; // CGy of Area Reduction from Pole Center including shell rot, sectors and segments
            
        }
        
        this.IxxCG = (this.SRMOI + (this.SRArea * this.CGy * this.CGy)) - (TotalIxxCG + (TotalArea * Math.pow(this.CGy - TotalCGy, 2)));
        this.IyyCG = (this.SRMOI + (this.SRArea * this.CGx * this.CGx)) - (TotalIyyCG + (TotalArea * Math.pow(this.CGx - TotalCGx, 2)));
        this.IxyCG = (this.SRArea * this.CGx * this.CGy) - (TotalIxyCG + (TotalArea * ((this.CGy - TotalCGy) * (this.CGx - TotalCGx))));
        //Determine the Maximum Principal MOI and Minimum Principal MOI about CG of remaining pole section area
        R = 0;
        //If (IxxCG <> IyyCG) Or (IxyCG <> 0) Then
        if ((Math.abs(this.IxxCG - this.IyyCG) > 0.000000000001) || (Math.abs(this.IxyCG) > 0.000000000001))
            R = Math.sqrt((Math.pow(this.IxxCG - this.IyyCG, 2) / 4) + (this.IxyCG * this.IxyCG));
        this.MOImax = ((this.IxxCG + this.IyyCG) / 2) + R; //Maximum Principal MOI
        this.MOImin = this.MOImax - (2 * R); //Minimum Principal MOI
        //Determine the principal centroidal axes angles of the remaining pole section area about CG of remaining pole section area
        var O = this.GetAngleDouble((parseFloat((this.IxxCG - this.IyyCG).toFixed(9))), parseFloat((-2 * this.IxyCG).toFixed(9))) / 2.0; //degrees
        var Ixx = ((this.IxxCG + this.IyyCG) / 2) + ((this.IxxCG - this.IyyCG) / 2) * Math.cos(2 * O / this.DRCON) - this.IxyCG * Math.sin(2 * O / this.DRCON); //= MOImax
        //////////////////////
        this.Omax = this.IIf(Math.abs(Ixx - this.MOImax) <= Math.abs(Ixx - this.MOImin), O, (O - 90));
        if (this.Omax < -90)
            this.Omax = (this.Omax + 180);
        this.Omin = (this.Omax + 90);
    }
    IIf(cond, dbl1, dbl2) {
        if (cond)
            return dbl1;
        return dbl2;
    }
    // public static IIf(cond: boolean, dbl1: number, dbl2: number): number {
    //     if (cond)
    //         return dbl1;
    //     return dbl2;
    // }
    Sgn(s) {
        if (s == 0)
            return 0;
        else if (s > 0)
            return 1;
        return -1;
    }
    GetAngleDouble(X, Y) {
        var GetAngleDouble = 0;
        if (X == 0) //Either 90, 0 or -90
            GetAngleDouble = 90 * this.Sgn(Y); //Considers if Y = 0 then GetAngleDouble = 0
        else if (Y == 0) //Either 0 or 180
         {
            GetAngleDouble = 0; //Considers if X = 0 then GetAngleDouble = 0
            if (X < 0)
                GetAngleDouble = 180;
        }
        else if (Math.abs(X) == Math.abs(Y)) //Either +/-45 or +/-135
            GetAngleDouble = this.IIf(X > 0, 45, 135) * this.Sgn(Y);
        else {
            GetAngleDouble = Math.atan(Y / X) * 57.2957795130823;
            if (X < 0)
                GetAngleDouble += 180;
            if (GetAngleDouble > 180)
                GetAngleDouble = GetAngleDouble - 360;
        }
        return GetAngleDouble;
    }
    CosO(deg) {
        return Math.cos(Math.PI * deg / 180.0);
    }
    SinO(deg) {
        return Math.sin(Math.PI * deg / 180.0);
    }
    SolveForStrength() {
        this.OuterSurfaceRadii = new Array(361);
        var C1 = 0, C2 = 0, C3 = 0;
        var bExternalDecay = false;
        if (this.Chords.length > 0)
            bExternalDecay = true;
        var iMinRow = 0; //Bending Moment Axis Angle for Minimum Tensile Stress Remaining Strength Ratio
        var iMaxRow = 0; //Bending Moment Axis Angle for Maximum Tensile Stress Remaining Strength Ratio
        var iRowMin = 0; //Bending Moment Axis Angle for Minimum Compressive Stress Remaining Strength Ratio
        var iRowMax = 0; //Bending Moment Axis Angle for Maximum Compressive Stress Remaining Strength Ratio
        var dMaxR = 0;
        var dMinR = 0;
        for (let i = 0; i < 181; i++) //degrees
         {
            this.OuterSurfaceRadii[i] = this.SRRadius;
            this.OuterSurfaceRadii[i + 180] = this.SRRadius;
        }
        //Section for Circular Chord of damage
        //Fill array OuterSurfaceRadii(i) with outer surface radii for each angle location on pole surface
        for (let ch of this.Chords) {
            dMinR = ch.MinR(this.OGRadius, this.SRRadius); //in circular Chord minimum radius at Chord centerline angle
            var iMinO = parseInt(ch.MinODeg2(this.OGRadius, this.SRRadius).toString()); //deg round minimum Chord angle up
            var iMaxO = parseInt(ch.MaxODeg2(this.OGRadius, this.SRRadius).toString()); //deg round maximum Chord angle up
            if (iMinO == iMaxO) //use minimum of 2deg for Chord total angle
             {
                iMaxO = iMaxO + 1;
                iMinO = iMinO - 1;
            }
            if (dMinR == 0)
                for (let i = iMinO; i <= iMaxO; i++) {
                    var j = i + this.IIf(i < 0, 360, this.IIf(i >= 360, -360, 0)); //deg keep range within 0 to 360
                    this.OuterSurfaceRadii[j] = 0;
                }
            else {
                var X = ch.Orientation; //deg circular Chord centerline angle
                for (let i = iMinO; i <= iMaxO; i++) //skipped if iMinO > iMaxO
                 {
                    var O = (i - X); //deg angle from circular Chord centerline angle
                    if (Math.abs(O) < 90) {
                        var R = dMinR / Math.cos(O / this.DRCON); //in radial distance to the point on the Chord line
                        if (R > this.SRRadius)
                            R = this.SRRadius;
                        var j = i + this.IIf(i < 0, 360, this.IIf(i >= 360, -360, 0)); //deg keep range within 0 to 360
                        if (R < this.OuterSurfaceRadii[j])
                            this.OuterSurfaceRadii[j] = R;
                    }
                }
            }
        }
        if (this.OuterSurfaceRadii[0] < this.OuterSurfaceRadii[360])
            this.OuterSurfaceRadii[360] = this.OuterSurfaceRadii[0];
        else if (this.OuterSurfaceRadii[360] < this.OuterSurfaceRadii[0])
            this.OuterSurfaceRadii[0] = this.OuterSurfaceRadii[360];
        var nSectors = this.Sectors.length;
        if (nSectors > 0) //a sector of decay exists
         {
            var iRadiiUbound = 1 + 2 * nSectors;
            var Radii = new Array(iRadiiUbound + 1);
            var SectorRadiusMin = new Array(nSectors);
            var SectorRadiusMax = new Array(nSectors);
            var iSectorAngleMin = new Array(nSectors);
            var iSectorAngleMax = new Array(nSectors);
            Radii[0] = this.SRRadius;
            Radii[1] = this.SRRadius;
            let k = 0;
            for (let sec of this.Sectors) {
                //////////
                dMinR = this.IIf((sec.MinR(this.OGRadius, this.SRRadius) < this.SRRadius), sec.MinR(this.OGRadius, this.SRRadius), this.SRRadius); //in decay sector minimum radius
                dMaxR = this.IIf((sec.MaxR(this.OGRadius, this.SRRadius) >= this.SRRadius), this.SRRadius, sec.MaxR(this.OGRadius, this.SRRadius)); //in decay sector maximum radius
                var iMinO = parseInt(sec.MinODeg(this.OGRadius).toFixed()); //deg sector minimum angle rounded off to nearest integer
                var iMaxO = parseInt(sec.MaxODeg(this.OGRadius).toFixed()); //deg sector maximum angle rounded off to nearest integer
                if (iMinO == iMaxO) //use minimum of 2deg for sector total angle
                 {
                    iMaxO = iMaxO + 1;
                    iMinO = iMinO - 1;
                }
                SectorRadiusMin[k] = dMinR; //in decay sector minimum radius considering shell rot
                SectorRadiusMax[k] = dMaxR; //in decay sector maximum radius considering shell rot
                iSectorAngleMin[k] = iMinO; //in decay sector minimum angle
                iSectorAngleMax[k] = iMaxO; //in decay sector maximum angle
                Radii[k + 2] = dMaxR; //in Create an array of all decay sector radii
                Radii[k + 2 + nSectors] = dMinR; //in Create an array of all decay sector radii
                k++;
            }
            for (let j = 2; j <= iRadiiUbound; j++)
                if (Math.abs(Radii[j] - this.SRRadius) <= 0.001)
                    Radii[j] = this.SRRadius;
            //Sort sector Radii() in order of decreasing radius
            for (let j = 1; j <= iRadiiUbound; j++) {
                var R = Radii[j];
                var i = j;
                for (i = j; i >= 1; i--)
                    if (Radii[i - 1] < R)
                        Radii[i] = Radii[i - 1];
                    else
                        break;
                Radii[i] = R;
            }
            if (Math.abs(Radii[2] - this.SRRadius) < 0.001)
                bExternalDecay = true;
            //Remove any redundant values from the sorted Radii() starting at index 1
            var nRows = iRadiiUbound;
            k = (nRows - 1);
            for (let j = 1; j <= k; j++)
                if (Math.abs(Radii[j] - Radii[j + 1]) < 0.001 && (Radii[j + 1] != -1)) {
                    for (let i = (j + 1); i <= (nRows - 1); i++)
                        Radii[i] = Radii[i + 1];
                    Radii[nRows] = -1;
                    nRows = nRows - 1;
                    j = j - 1;
                }
            //Reduce array size after removal of redundant rows from Radii()
            if (nRows < iRadiiUbound) {
                iRadiiUbound = nRows;
                var RadiiList = Radii;
                RadiiList.pop();
                Radii = RadiiList;
            }
            var bRadiusMap = [...Array(361)].map(e => Array(iRadiiUbound + 1));
            //ReDim bRadiusMap(0 To 360, 1 To iRadiiUbound)
            let m = 0;
            k = 0;
            //Set all bRadiusMap() points with decay to True
            for (let sec of this.Sectors) {
                iMinRow = 0;
                iMaxRow = 0;
                dMinR = sec.MinR(this.OGRadius, this.SRRadius); //in decay sector minimum radius considering shell rot
                dMaxR = sec.MaxR(this.OGRadius, this.SRRadius); //in decay sector maximum radius considering shell rot
                for (let j = 1; j <= iRadiiUbound; j++) //find minimum and maximum Radii() rows with decay
                 {
                    if (iMinRow == 0)
                        if (Math.abs(Radii[j] - dMaxR) < 0.001)
                            iMinRow = j; //minimum row in Radii() with decay
                    if (Math.abs(Radii[j] - dMinR) < 0.001) //maximum row in Radii() with decay
                     {
                        iMaxRow = j - 1;
                        break;
                    }
                }
                //Sweep the range of angles for each sector
                var iMinO = iSectorAngleMin[k];
                var iMaxO = iSectorAngleMax[k];
                for (let i = (iMinO + 1); i <= (iMaxO - 1); i++) {
                    if ((i > 0) && (i < 360))
                        m = i;
                    else if ((i == 0) || (i == 360))
                        m = 0;
                    else //Outside range of 0 to 360
                        m = i + this.IIf(i < 0, 360, this.IIf(i > 360, -360, 0)); //deg keep range within 0 to 360
                    for (let j = iMinRow; j <= iMaxRow; j++) //set the range of decay values to True
                        bRadiusMap[m][j] = true;
                }
                k++;
            }
            for (let j = 1; j <= iRadiiUbound; j++)
                bRadiusMap[360][j] = bRadiusMap[0][j];
            //Fill array OuterSurfaceRadii(i) with outer surface radii for each angle location on pole surface using bRadiusMap()
            m = iRadiiUbound - 1;
            for (let i = 0; i <= 360; i++) //degrees
             {
                dMinR = this.OuterSurfaceRadii[i];
                for (let j = m; j >= 1; j--)
                    if (bRadiusMap[i][(j + 1)])
                        if (!bRadiusMap[i][j]) //bRadiusMap value is already True
                            if (dMinR < Radii[j])
                                if (dMinR < Radii[j + 1])
                                    bRadiusMap[i][j] = true; //Internal sector is exposed
                for (let j = 1; j <= iRadiiUbound; j++)
                    if (!bRadiusMap[i][j]) //search for first occurrence of False
                     {
                        if (Radii[j] < dMinR)
                            this.OuterSurfaceRadii[i] = Radii[j]; //set reduced outer surface radius
                        break; //Exit after first occurrence of False
                    }
            }
            //Remove possible OuterSurfaceRadii() spike when adjacent sectors are touching each other or a sector arc length is 360º
            if (nSectors > 1)
                for (let i = 1; i <= nSectors; i++) //sector min angle
                 {
                    k = iSectorAngleMin[i - 1];
                    k = k + this.IIf(k < 0, 360, this.IIf(k >= 360, -360, 0)); //deg keep range within 0 to 360
                    for (let j = 1; j <= nSectors; j++) //sector max angle
                     {
                        m = iSectorAngleMax[j - 1];
                        m = m + this.IIf(m < 0, 360, this.IIf(m >= 360, -360, 0)); //deg keep range within 0 to 360
                        if (k == m)
                            if (k == 0) {
                                if (this.OuterSurfaceRadii[0] > this.OuterSurfaceRadii[1])
                                    if (this.OuterSurfaceRadii[0] > this.OuterSurfaceRadii[359]) {
                                        m = this.IIf(this.OuterSurfaceRadii[1] >= this.OuterSurfaceRadii[359], 1, 359);
                                        this.OuterSurfaceRadii[0] = this.OuterSurfaceRadii[m];
                                        this.OuterSurfaceRadii[360] = this.OuterSurfaceRadii[0];
                                    }
                            }
                            else if (this.OuterSurfaceRadii[k] > this.OuterSurfaceRadii[k + 1])
                                if (this.OuterSurfaceRadii[k] > this.OuterSurfaceRadii[k - 1]) {
                                    m = k + this.IIf(this.OuterSurfaceRadii[k + 1] > this.OuterSurfaceRadii[k - 1], 1, -1);
                                    this.OuterSurfaceRadii[k] = this.OuterSurfaceRadii[m];
                                }
                    }
                }
        }
        //Solve for Neutral Axis Angle for input Bending Moment Axis Angle
        this.BMAxis = 0;
        var dX = 0, dY = 0;
        C1 = 0;
        C2 = 0;
        
        if (this.BMAxis == 0) {
            dX = 1; //cosine of Bending Moment Axis Angle
            dY = 0; //sine of Bending Moment Axis Angle
            C1 = this.IyyCG; //delta X for Neutral Axis Angle
            C2 = this.IxyCG; //delta Y for Neutral Axis Angle     
        }
        else {
            dX = Math.cos(this.BMAxis / this.DRCON); //cosine of Bending Moment Axis Angle
            dY = Math.sin(this.BMAxis / this.DRCON); //sine of Bending Moment Axis Angle
            C1 = (this.IyyCG * dX) + (this.IxyCG * dY); //Neutral Axis Angle delta X
            C2 = (this.IxxCG * dY) + (this.IxyCG * dX); //Neutral Axis Angle delta Y        
        }
        C3 = ((this.IxxCG * this.IyyCG) - (this.IxyCG * this.IxyCG));
        var NAAxis = this.GetAngleDouble(C1, C2); //deg Neutral Axis Angle for Bending Moment Axis Angle BMAxis
        NAAxis = NAAxis + this.IIf(parseFloat(NAAxis.toFixed(9)) < 0, 360, this.IIf(parseFloat((NAAxis).toFixed(9)) > 360, -360, 0)); //deg keep range within 0 to 360
        dX = Math.cos(NAAxis / this.DRCON); //cosine of Neutral Axis Angle
        dY = Math.sin(NAAxis / this.DRCON); //sine of Neutral Axis Angle
        //Solve for minimum distance from origin to neutral axis for input Bending Moment Axis Angle
        //D1 = (CGx + dX) * CGy - (CGy + dY) * CGx  //minimum distance from origin to neutral axis for input Bending Moment Axis Angle
        var D1 = ((this.CGy * dX) - (this.CGx * dY)); //minimum distance from origin to neutral axis for input Bending Moment Axis Angle
        var D2 = 0;
        var Zmin = 0;
        var Zmax = 0;
        this.OuterFiberDistanceMax = new Array(361);
        this.OuterFiberDistanceMin = new Array(361);
        this.OuterFiberDistance = new Array(361);
        this.RSRmin = new Array(361);
        this.RSRmax = new Array(361);
        this.NAxis = new Array(361);
        this.NAxisDiff = new Array(361);
        this.SectionModulusReciprocal = new Array(361);
        if (bExternalDecay){        
            for (let i = 0; i <= 360; i++) //deg pole outer surface location angle
             {
                //Calculate Distance from neutral axis to outer surface points for input Bending Moment Axis Angle
                //The following OuterFiberDistance(i) is only for the spreadsheet "Properties" charts and not needed in the final program
                this.OuterFiberDistance[i] = ((this.OuterSurfaceRadii[i] * ((dX * this.SinO(i)) - (dY * this.CosO(i)))) - D1);
                //Calculate Reciprocal of Section Modulus for outer surface points for Input Bending Moment Axis Angle
                
                          
                D2 = C1 * ((this.OuterSurfaceRadii[i] * this.SinO(i)) - this.CGy) - C2 * ((this.OuterSurfaceRadii[i] * this.CosO(i)) - this.CGx);
                D2 = D2 / C3;
                this.SectionModulusReciprocal[i] = D2; //Reciprocal of Section Modulus
                if (D2 < Zmin)
                    Zmin = D2; //Minimum Reciprocal of Section Modulus
                if (D2 > Zmax)
                    Zmax = D2; //Maximum Reciprocal of Section Modulus
            }
        }
        else {
            //The following For i ... Next i is only for the spreadsheet "Properties" charts and not needed in the final program
            for (let i = 0; i <= 360; i++) //deg pole outer surface location angle
             {
                //Calculate Distance from neutral axis to outer surface points for input Bending Moment Axis Angle
                this.OuterFiberDistance[i] = ((this.SRRadius * ((dX * this.SinO(i)) - (dY * this.CosO(i)))) - D1);
                //Calculate Reciprocal of Section Modulus for outer surface points for Input Bending Moment Axis Angle
                D2 = C1 * ((this.SRRadius * this.SinO(i)) - this.CGy) - C2 * ((this.SRRadius * this.CosO(i)) - this.CGx);
                D2 = D2 / C3;
                this.SectionModulusReciprocal[i] = D2; //Reciprocal of Section Modulus
            }
            //Calculate Maximum and Minimum Reciprocal of Section Modulus for Input Bending Moment Axis Angle
            var O = (NAAxis + 90) / this.DRCON;
            D2 = C1 * (this.SRRadius * Math.sin(O) - this.CGy) - C2 * (this.SRRadius * Math.cos(O) - this.CGx);
            Zmin = D2 / C3;
            Zmax = Zmin;
            O = (NAAxis - 90) / this.DRCON;
            D2 = C1 * (this.SRRadius * Math.sin(O) - this.CGy) - C2 * (this.SRRadius * Math.cos(O) - this.CGx);
            D2 = D2 / C3;
            if (Zmin < 0)
                Zmax = D2; //Maximum Reciprocal of Section Modulus
            else
                Zmin = D2; //Minimum Reciprocal of Section Modulus
        }
        var OriginalPoleZ = this.OGArea * this.OGRadius / 4;
        var CompressiveStrengthRemaining = (1 / (Zmin * OriginalPoleZ));
        //Sweep Bending Moment Axis Angle to determine Remaining Strength Ratios
        C3 = ((this.IxxCG * this.IyyCG) - (this.IxyCG * this.IxyCG));
        var dMinO = 1E+99; //Minimum Tensile Stress Remaining Strength Ratio
        var dMaxO = 0; //Maximum Tensile Stress Remaining Strength Ratio
        var dMinO1 = 1E+99; //Minimum Compressive Stress Remaining Strength Ratio
        var dMaxO1 = 0; //Maximum Compressive Stress Remaining Strength Ratio
        for (let j = 0; j <= 360; j++) //deg Sweep Bending Moment Axis Angle j
         {
            //Solve for Neutral Axis Angle for Bending Moment Axis Angle j
            C1 = ((this.IyyCG * this.CosO(j)) + (this.IxyCG * this.SinO(j))); //Neutral Axis Angle delta X
            C2 = ((this.IxxCG * this.SinO(j)) + (this.IxyCG * this.CosO(j))); //Neutral Axis Angle delta Y
            NAAxis = this.GetAngleDouble(C1, C2); //deg Neutral Axis Angle for Bending Moment Axis Angle j
            NAAxis = NAAxis + this.IIf(parseFloat(NAAxis.toFixed(9)) < 0, 360, this.IIf(parseFloat(NAAxis.toFixed(9)) > 360, -360, 0)); //deg keep range within 0 to 360
            this.NAxis[j] = NAAxis; //Neutral Axis Angle for Bending Moment Axis Angle j
            var O = (NAAxis - j); //Neutral Axis Angle difference from Bending Moment Axis Angle j
            O = O + this.IIf(parseFloat(O.toFixed(9)) <= -180, 360, this.IIf(parseFloat(O.toFixed(9)) > 180, -360, 0));
            this.NAxisDiff[j] = O; //Neutral Axis Angle difference from Bending Moment Axis Angle j
            //Solve for minimum distance from origin to neutral axis for Bending Moment Axis Angle j
            dX = Math.cos(NAAxis / this.DRCON);
            dY = Math.sin(NAAxis / this.DRCON);
            //Y = (CGx + dX) * CGy - (CGy + dY) * CGx  //minimum distance from origin to neutral axis for Bending Moment Axis Angle j
            var Y = ((this.CGy * dX) - (this.CGx * dY)); //minimum distance from origin to neutral axis for Bending Moment Axis Angle j
            //Distance from neutral axis to outer surface points for Bending Moment Axis Angle
            dMaxR = 0;
            dMinR = 0;
            //Reciprocal of Section Modulus
            Zmin = 0;
            Zmax = 0;
            if (bExternalDecay){
                for (let i = 0; i <= 360; i++) //deg Sweep Surface Location Angle to find Outer Fiber Distance & Remaining Strength Ratio min/max
                 {
                    D1 = this.OuterSurfaceRadii[i];
                    var R = ((D1 * ((dX * this.SinO(i)) - (dY * this.CosO(i)))) - Y); //Outer Fiber Distance
                    if (R > dMaxR)
                        dMaxR = R; //Maximum Outer Fiber Distance
                    if (R < dMinR)
                        dMinR = R; //Minimum Outer Fiber Distance
                    D2 = ((D1 * this.SinO(i)) - this.CGy) * C1 - ((D1 * this.CosO(i)) - this.CGx) * C2;
                    D2 = D2 / C3; //Reciprocal of Section Modulus
                    //SectionModulusReciprocal[i] = D2  //Reciprocal of Section Modulus
                    if (D2 < Zmin)
                        Zmin = D2; //Minimun Reciprocal of Section Modulus
                    if (D2 > Zmax)
                        Zmax = D2; //Maximun Reciprocal of Section Modulus
                }
            }
            else {
                D1 = this.SRRadius;
                O = (NAAxis + 90) / this.DRCON;
                dMaxR = (D1 * ((dX * Math.sin(O)) - (dY * Math.cos(O)))); //Outer Fiber Distance from origin (should be the Shell Rot Pole Radius)
                dMaxR = (dMaxR - Y); //Outer Fiber Distance from CG
                dMinR = dMaxR;
                Zmax = ((D1 * Math.sin(O)) - this.CGy) * C1 - ((D1 * Math.cos(O)) - this.CGx) * C2;
                Zmax /= C3; //Reciprocal of Section Modulus
                Zmin = Zmax;
                O = (NAAxis - 90) / this.DRCON;
                D2 = (D1 * ((dX * Math.sin(O)) - (dY * Math.cos(O)))); //Outer Fiber Distance from origin (should be the Shell Rot Pole Radius)
                D2 = (D2 - Y); //Outer Fiber Distance from CG
                if (dMinR < 0)
                    dMaxR = D2; //Maximum Outer Fiber Distance
                else
                    dMinR = D2; //Minimum Outer Fiber Distance
                D2 = ((D1 * Math.sin(O)) - this.CGy) * C1 - ((D1 * Math.cos(O)) - this.CGx) * C2;
                D2 = D2 / C3; //Reciprocal of Section Modulus
                if (Zmin < 0)
                    Zmax = D2; //Maximum Reciprocal of Section Modulus
                else
                    Zmin = D2; //Minimum Reciprocal of Section Modulus
            }
            this.OuterFiberDistanceMax[j] = dMaxR; //Maximum Outer Fiber Distance
            this.OuterFiberDistanceMin[j] = dMinR; //Minimum Outer Fiber Distance
            
            //should be equal to Zmax = 0.010331007948573069
            this.RSRmax[j] = parseFloat((1 / (Zmax * OriginalPoleZ)).toFixed(6)); //Tensile Stress Remaining Strength Ratio
            this.RSRmin[j] = parseFloat((1 / (Zmin * OriginalPoleZ)).toFixed(6)); //Compressive Stress Remaining Strength Ratio
            if (this.RSRmax[j] < (dMinO * 0.99999999999999)) {
                dMinO = this.RSRmax[j];
                iMinRow = j;
            }
            if (this.RSRmax[j] > (dMaxO * 1.0000000000001)) {
                dMaxO = this.RSRmax[j];
                iMaxRow = j;
            }
            if (-this.RSRmin[j] < (dMinO1 * 0.99999999999999)) {
                dMinO1 = -this.RSRmin[j];
                iRowMin = j;
            }
            if (-this.RSRmin[j] > (dMaxO1 * 1.0000000000001)) {
                dMaxO1 = -this.RSRmin[j];
                iRowMax = j;
            }
        }
        var RSRmaxList = this.RSRmax;
        this.MaxRemainingStrengthPrecise = 100.0 * Math.max(...RSRmaxList);
        this.MaxRemDegBend = RSRmaxList.indexOf(Math.max(...RSRmaxList));
        this.MinRemainingStrengthPrecise = 100.0 * Math.min(...this.RSRmax);
        this.MinRemDegBend = RSRmaxList.indexOf(Math.min(...RSRmaxList));
        if (Math.abs(this.RSRmax[0]) < Math.abs(this.RSRmin[0])){
            this.RemainingStrengthAtLoLPrecise = 100.0 * this.RSRmax[0];
        }  
        else{
            this.RemainingStrengthAtLoLPrecise = 100.0 * Math.abs(this.RSRmin[0]);
        }
    }
    BucklingStrengthFactor() {
        var secs = this.Sectors.filter(x => x.Shell > 1e-5);
        if (secs.length < 1)
            return 1.0;
        var thick1Secs = secs.filter(x => x.OrientationIndex == 2 && x.Shell > 1e-5).map(x => x.Shell);
        var thick2Secs = secs.filter(x => x.OrientationIndex == 6 && x.Shell > 1e-5).map(x => x.Shell);
        var thick3Secs = secs.filter(x => x.OrientationIndex == 0 && x.Shell > 1e-5).map(x => x.Shell);
        var thick4Secs = secs.filter(x => x.OrientationIndex == 4 && x.Shell > 1e-5).map(x => x.Shell);
        if (thick1Secs.length < 1 || thick2Secs.length < 1 || thick3Secs.length < 1 || thick4Secs.length < 1)
            return 1.0;
        var thick1 = 2;
        var thick2 = 2;
        var thick3 = 2;
        var thick4 = 2;
        if (thick1Secs.length > 0)
            thick1 = Math.min(...thick1Secs);
        if (thick2Secs.length > 0)
            thick2 = Math.min(...thick2Secs);
        if (thick3Secs.length > 0)
            thick3 = Math.min(...thick3Secs);
        if (thick4Secs.length > 0)
            thick4 = Math.min(...thick4Secs);
        var thickTrans = Math.min(thick1, thick2);
        var thickLong = Math.min(thick3, thick4);
        var factor1 = Math.sqrt(thickTrans / ((this.cOriginalCircumference / Math.PI) * 0.18));
        var factor2 = 1;
        if (thickLong < thickTrans)
            factor2 = Math.min(1, Math.sqrt(thickLong / thickTrans));
        return Math.min(1, factor1 * factor2);
    }
}
exports.StrengthCalcV4 = StrengthCalcV4;
