"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Chord = void 0;
const Damage_1 = require("./Damage");
class Chord extends Damage_1.Damage {
    constructor(pDamage) {
        super();
        this.DamageType = pDamage !== null && pDamage !== void 0 ? pDamage : this.DamageType;
    }
    ChordWidth(OGRadius) {
        return 2 * OGRadius * Math.sin(this.AngleRad(OGRadius) / 2.0);
    }
    ChordHalfWidth(OGRadius) {
        return 0.5 * this.ChordWidth(OGRadius);
    }
    ChordDepth(OGRadius) {
        return OGRadius * (1 - Math.cos(this.AngleRad(OGRadius) / 2.0));
    }
    Area(OGRadius) {
        var chordAngleRad = this.AngleRad(OGRadius);
        return 0.5 * (chordAngleRad - Math.sin(chordAngleRad)) * Math.pow(OGRadius, 2);
    }
    Area2(OGRadius, SRRadius) {
        var chordAngleRad = this.AngleRad(OGRadius);
        var dMaxR2 = Math.pow(SRRadius, 2);
        var dMinR = OGRadius * Math.cos(chordAngleRad / 2);
        var C1 = dMaxR2 - dMinR * dMinR;
        if (C1 < 0)
            return 0;
        C1 = Math.sqrt(C1);
        var C2 = Math.acos(OGRadius * Math.cos(chordAngleRad / 2) / SRRadius);
        return dMaxR2 * C2 - dMinR * C1;
    }
    MinR(OGRadius, SRRadius) {
        var minr = OGRadius * Math.cos(this.AngleRad(OGRadius) / 2.0);
        if (minr >= SRRadius)
            return -1;
        return minr;
    }
    MaxORad2(OGRadius, SRRadius) {
        var chordAngleRad = this.AngleRad(OGRadius);
        var C2 = Math.acos(OGRadius * Math.cos(chordAngleRad / 2.0) / SRRadius);
        var maxo = this.OrientationRad + C2;
        return maxo;
    }
    MaxODeg2(OGRadius, SRRadius) {
        return parseFloat((this.MaxORad2(OGRadius, SRRadius) * 180 / Math.PI).toFixed(6));
    }
    MinORad2(OGRadius, SRRadius) {
        var chordAngleRad = this.AngleRad(OGRadius);
        var C2 = Math.acos(OGRadius * Math.cos(chordAngleRad / 2.0) / SRRadius);
        var mino = this.OrientationRad - C2;
        return mino;
    }
    MinODeg2(OGRadius, SRRadius) {
        return parseFloat((this.MinORad2(OGRadius, SRRadius) * 180 / Math.PI).toFixed(6));
    }
    get Summary() {
        return "{\"Orient\": \"" + this.Orientation + "\",\"ArcLen\": " + this.ArcLen +
            ",\"Source\": \"" + Chord.GetDescriptionSourceType(this.Source) + "\"DamageType\": \"" +
            Chord.GetDescriptionDamageType(this.DamageType) + "\"}";
    }
}
exports.Chord = Chord;
