"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Damage = exports.SourceTypes = exports.DamageTypes = void 0;
const guid_typescript_1 = require("guid-typescript");
var DamageTypes;
(function (DamageTypes) {
    // [Description("Unknown")]
    DamageTypes[DamageTypes["Unknown"] = 0] = "Unknown";
    // [Description("ExposedPocket")]
    DamageTypes[DamageTypes["ExposedPocket"] = 1] = "ExposedPocket";
    //  [Description("EnclosedPocket")]
    DamageTypes[DamageTypes["EnclosedPocket"] = 2] = "EnclosedPocket";
    //  [Description("HollowPocket")]
    DamageTypes[DamageTypes["HollowPocket"] = 3] = "HollowPocket";
    //  [Description("MechanicalDamage")]
    DamageTypes[DamageTypes["MechanicalDamage"] = 4] = "MechanicalDamage";
    //  [Description("ShellRot")]
    DamageTypes[DamageTypes["ShellRot"] = 5] = "ShellRot";
})(DamageTypes = exports.DamageTypes || (exports.DamageTypes = {}));
var SourceTypes;
(function (SourceTypes) {
    //[Description("Manual")]
    SourceTypes[SourceTypes["Manual"] = 0] = "Manual";
})(SourceTypes = exports.SourceTypes || (exports.SourceTypes = {}));
class Damage {
    constructor() {
        this.ArcLen = 0;
        this.Overlap = false;
        this.HasAnError = false;
        this.cDamageGuid = guid_typescript_1.Guid.create();
        this.DamageType = DamageTypes.Unknown;
        this.Source = SourceTypes.Manual;
    }
    get OrientationRad() {
        return this.Orientation * Math.PI / 180.0;
    }
    get OrientationIndex() {
        return parseInt((this.Orientation / 45.0).toFixed());
    }
    get OrientationString() {
        if (this.Orientation == 0 || this.Orientation == 360)
            return "+LoL";
        if (this.Orientation == 180)
            return "-LoL";
        if (this.Orientation > 180)
            return (this.Orientation - 360).toString();
        return this.Orientation.toFixed(); //Use # in toString()
    }
    get DamageGuid() { return this.cDamageGuid; }
    static GetDescriptionDamageType(en) {
        return DamageTypes[en];
    }
    static GetDescriptionSourceType(en) {
        return SourceTypes[en];
    }
    static GetEnumFromDamageDescription(description) {
        return DamageTypes[description];
    }
    AngleRad(OGRadius) {
        if (OGRadius == 0)
            return 0;
        return this.ArcLen / (OGRadius);
    }
    AngleDeg(OGRadius) {
        if (OGRadius == 0)
            return 0;
        return 180.0 * this.AngleRad(OGRadius) / Math.PI;
    }
    MaxORad(OGRadius) {
        var maxo = this.OrientationRad + 0.5 * this.AngleRad(OGRadius);
        return maxo;
    }
    MaxODeg(OGRadius) {
        return parseFloat((this.MaxORad(OGRadius) * 180 / Math.PI).toFixed(6));
    }
    MinORad(OGRadius) {
        var mino = this.OrientationRad - 0.5 * this.AngleRad(OGRadius);
        return mino;
    }
    MinODeg(OGRadius) {
        return parseFloat((this.MinORad(OGRadius) * 180 / Math.PI).toFixed(6));
    }
}
exports.Damage = Damage;
