"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sector = void 0;
const Damage_1 = require("./Damage");
class Sector extends Damage_1.Damage {
    constructor(pDamage) {
        super();
        this.Depth = 0;
        this.Shell = 0;
        this.DamageType = pDamage !== null && pDamage !== void 0 ? pDamage : this.DamageType;
    }
    Area(OGRadius) {
        var ThickSqr = Math.pow((OGRadius - this.Shell), 2);
        var DepthSqr = Math.pow((OGRadius - this.Depth), 2);
        return 0.5 * this.AngleRad(OGRadius) * (ThickSqr - DepthSqr);
    }
    Area2(OGRadius, SRRadius) {
        let area = -1;
        if (this.Shell >= this.Depth)
            return 0;
        if ((OGRadius - this.Shell) > SRRadius) {
            var dMaxR = OGRadius - this.Shell; //Pocket Sector Arc Outer Radius
            var dMinR = OGRadius - this.Depth; //Pocket Sector Arc Inner Radius
            if (SRRadius < OGRadius) //shell rot exists
             {
                dMaxR = (SRRadius - dMaxR); //in Depth from Shell Rot Surface
                if (dMaxR < 0)
                    dMaxR = 0;
                dMaxR = (SRRadius - dMaxR); //in Pocket Sector Arc Outer Radius
                if (dMinR > dMaxR)
                    dMinR = dMaxR; //in Pocket Sector Arc Inner Radius
            }
            if (dMinR < (dMaxR - 0.001))
                area = (dMaxR * dMaxR - dMinR * dMinR) * this.AngleRad(OGRadius) * .5; //in^2 Pocket Sector Area with shell rot
        }
        else
            return this.Area(OGRadius);
        return area;
    }
    MaxR(OGRadius, SRRad) {
        var maxr = OGRadius - this.Shell;
        if (maxr >= SRRad)
            return SRRad;
        return OGRadius - this.Shell;
    }
    MinR(OGRadius, SRRad) {
        var minr = OGRadius - this.Depth;
        if (minr >= SRRad)
            return -1;
        return OGRadius - this.Depth;
    }
    get Summary() {
        return "Orient: " + this.Orientation + "\nArcLen: " + this.ArcLen + "\nThick: " +
            this.Shell + "\nDepth: " + this.Depth +
            "\nSource: " + Sector.GetDescriptionSourceType(this.Source) + "\nDamageType: " +
            Sector.GetDescriptionDamageType(this.DamageType);
    }
}
exports.Sector = Sector;
