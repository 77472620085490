"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Error = void 0;
const Damage_1 = require("./Damage");
class Error {
    constructor(GUID, ErrorMessage, DamageType) {
        this.DamageType = Damage_1.DamageTypes.Unknown;
        this.GUID = GUID;
        this.ErrorMessage = ErrorMessage;
        this.DamageType = DamageType;
    }
}
exports.Error = Error;
