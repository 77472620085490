<template>
  <div class="ex1">
    <div ref="svgDiv" class="svg"></div>
  </div>
</template>
<script>
//global $, localStorage, angular, alert, document, console, confirm, require, chroma
/*jshint unused:false */
/*jshint plusplus: true, devel: true, nomen: true, indent: 4, maxerr: 50 */
export default {
  data() {
    return {
      circumference: 50,
      radiusIncrement: 1,
      scaleFactor: 3,
      stroke: 1,
      colorDial: "#0000ff",
      colorDark: "#003355",
      colorBright: "#0000ff",
      EnclosedPocketColor: "#FF0000",
      ExposedPocketPocketColor: "#800080",
      MechanicalPocketColor: "#000088",
      HollowPoleColor: "#BB4400",
      UnknownColor: "#888888",
      colorFill: "none",
      customWidth: "120px",

      radius: 0,
    };
  },
  //TODO: Addd validators for props, expose props for settings
  props: ["damages", "origCirc", "shellRotCirc", "refresh"],
  methods: {
    doDamageDraw() {
      var sampleDamages = [];
      sampleDamages.push({
        ID: "197c6bbe-8f18-4e6c-aa1b-9b6721480d99",
        TYPE: "EnclosedPocket",
        ORIENT: 0,
        ARCLEN: 2,
        SHELL: 0,
        DEPTH: 15,
        SOURCE: "Manual",
      });
      sampleDamages.push({
        ID: "197c6bbe-8f18-4e6c-aa1b-9b6721480d98",
        TYPE: "ExposedPocket",
        ORIENT: -45,
        ARCLEN: 2,
        SHELL: 1,
        DEPTH: 1,
        SOURCE: "Manual",
      });
      sampleDamages.push({
        ID: "abc-8f18-4e6c-aa1b-9b6721480d97",
        TYPE: "EnclosedPocket",
        ORIENT: -90,
        ARCLEN: 2,
        SHELL: 0,
        DEPTH: 1,
        SOURCE: "Manual",
      });
      sampleDamages.push({
        ID: "197c6bbe-8f18-4e6c-aa1b-9b6721480d96",
        TYPE: "Mechanical",
        ORIENT: -135,
        ARCLEN: 1,
        SHELL: 0,
        DEPTH: 1,
        SOURCE: "Manual",
      });
      sampleDamages.push({
        ID: "197c6bbe-8f18-4e6c-aa1b-9b6721480d95",
        TYPE: "EnclosedPocket",
        ORIENT: -180,
        ARCLEN: 1,
        SHELL: 0,
        DEPTH: 1,
        SOURCE: "Manual",
      });
      sampleDamages.push({
        ID: "197c6bbe-8f18-4e6c-aa1b-9b6721480d94",
        TYPE: "Unknown",
        ORIENT: 135,
        ARCLEN: 1,
        SHELL: 0,
        DEPTH: 1,
        SOURCE: "Manual",
      });
      sampleDamages.push({
        ID: "197c6bbe-8f18-4e6c-aa1b-9b6721480d93",
        TYPE: "HollowPole",
        ORIENT: 0,
        ARCLEN: 0,
        SHELL: 6,
        DEPTH: 0,
        SOURCE: "Manual",
      });
      // console.log("sampleDamages: " + sampleDamages.length);

      this.draw(sampleDamages);
    },

    draw(targetDamages) {
      this.radius = this.calculateRadius(this.circumference) * this.scaleFactor;

      // A few good pens helped create this https://codepen.io/netsi1964/pen/ZYpNMz/ and using https://codepen.io/netsi1964/pen/ZYpNMz/       /* Based on https://codepen.io/anon/pen/AkoGx */
      var addToRadius = this.radiusIncrement;
      var cx = this.radius;
      var cy = this.radius;
      var viewBox = addToRadius * -10;
      var w = this.radius * 2 + addToRadius * 20; //need to increase to add the ring with degrees

      var sSVG =
        '<svg style="background:#6c9ac3; border: 1px solid rgb(28, 55, 102);" viewBox="' +
        viewBox +
        " " +
        viewBox +
        " " +
        w +
        " " +
        w +
        '" version="1.1" xmlns="http://www.w3.org/2000/svg">';
      sSVG +=
        "\n\t<title>StrengthCalc Damage Map</title>\n\t<desc>Created by Lumen on " +
        new Date() +
        '</desc>\n \t<g id="SVG_ARC">%svg%\t</g>\n</svg>';
      var sCircles = "";
      var i = 1;

      let shellRotPercentage = (this.shellRotCirc * 100) / this.origCirc;
      let scaledShellRot =
        (50 - (this.circumference * shellRotPercentage) / 100) /
        this.scaleFactor;

      //Draw shell rot
      for (var j = this.radius; j > this.radius - scaledShellRot; j--) {
        sCircles +=
          '\n\t\t\t<circle id="circle' +
          i +
          '" cx="' +
          this.symetricRound(parseFloat(cx)) +
          '" cy="' +
          this.symetricRound(parseFloat(cy)) +
          '" r="' +
          this.symetricRound(parseFloat(j)) +
          '" stroke= "' +
          this.UnknownColor +
          '" stroke-width="' +
          this.stroke +
          '" />';
        i++;
      }

      //Draw concentric circles / pole rings
      for (
        var r = addToRadius;
        r <= this.radius - scaledShellRot;
        r += addToRadius
      ) {
        //output the circles to the svg
        sCircles +=
          '\n\t\t\t<circle id="circle' +
          i +
          '" cx="' +
          this.symetricRound(parseFloat(cx)) +
          '" cy="' +
          this.symetricRound(parseFloat(cy)) +
          '" r="' +
          this.symetricRound(parseFloat(r)) +
          '" />';
        i++;
      }

      //Add damage markers
      sCircles += this.getDamageArcCircle(
        targetDamages,
        this.radius,
        this.colorDark
      );
      var dial = this.drawLOLCircle(cx, cy);
      let arrowLine = `<g>
      <defs>
          <marker id="arrow" markerWidth="2" markerHeight="4" orient="auto" refX="0.1" refY="2" markerUnits="strokeWidth">
            <path d="M0,0 V4 L2,2 Z" fill="rgb(125,124,226)"></path>
          </marker>
      </defs>
          <line x1="0" y1="24.00004" x2="44" y2="24" stroke-width="2" stroke="rgb(125,124,226)" marker-end="url(#arrow)"></line>
          <line x1="0" y1="24.00004" x2="48" y2="24" stroke-width="0.5" stroke="black"></line>
          <line x1="23.999999999999996" y1="0" x2="24" y2="48" stroke-width="0.5" stroke="black"></line>
<line x1="42" y1="6.5" x2="6.5" y2="42" stroke-width="0.5" stroke="#fff" stroke-dasharray="1,2"></line>
<line x1="6.5" y1="6.5" x2="42" y2="42" stroke-width="0.5" stroke="#fff" stroke-dasharray="1,2"></line>
      </g>`;
      let mechanicalArc = this.getMechanicalDamagesArc(
        targetDamages,
        this.colorDark
      );
      sCircles =
        '\t\t<g id="circles" width="100%" height="100%"  stroke-width="' +
        this.stroke +
        '" fill="none" stroke="' +
        this.colorBright +
        '">' +
        sCircles +
        "\n\t\t</g>" +
        mechanicalArc +
        arrowLine;
      sSVG = sSVG.replace("%svg%", sCircles + dial);
      this.$refs.svgDiv.innerHTML = sSVG;
    },
    //Draw degree text, dots and LOL
    drawLOLCircle(cx, cy) {
      var degreeDial = "";
      var dotsPerCircle = 8;

      var interval = (Math.PI * 2) / dotsPerCircle;
      var desiredRadianAngleOnCircle = 0;

      var centerX = cx;
      var centerY = cy;
      var radius =
        this.calculateRadius(this.circumference) * this.scaleFactor +
        this.radiusIncrement * 3;

      for (var i = 0; i < dotsPerCircle; i++) {
        desiredRadianAngleOnCircle = interval * i;

        var x = centerX + radius * Math.cos(desiredRadianAngleOnCircle);
        var y = centerY + radius * Math.sin(desiredRadianAngleOnCircle);
        var currentDegree = i * 45;
        var degreeDisplay = "-1";
        switch (currentDegree) {
          case 0:
            degreeDisplay = " +LoL";
            break;
          case 45:
            degreeDisplay = " -45 &deg;";
            break;
          case 90:
            degreeDisplay = "-90 &deg; ";
            break;
          case 135:
            degreeDisplay = "-135 &deg; ";
            break;
          case 180:
            degreeDisplay = "-LoL ";
            break;
          case 225:
            degreeDisplay = "135 &deg; ";
            break;
          case 270:
            degreeDisplay = "90 &deg; ";
            break;
          case 315:
            degreeDisplay = " 45 &deg;";
            break;
          default:
            degreeDisplay = "-1"; //Should never happen .. but will be obvious if it does :-)
        }

        degreeDial +=
          '\n\t\t\t<g><circle id="degree' +
          i +
          '" cx="' +
          x +
          '" cy="' +
          y +
          '" r="' +
          "0" +
          '"  stroke="none" fill="' +
          "#6c9ac3" +
          '"/></circle>' +
          '<text x="' +
          x +
          '" y="' +
          y +
          '"  font-size=".1em" text-anchor="middle" dy=".03em" fill="' +
          "#ffffff" +
          '">' +
          degreeDisplay +
          "</text></g>";
      }

      return degreeDial;
    },

    //Add Damage graphic to svg
    getDamageArcCircle(damages, radius) {
      //Use radius to set center
      var cx = radius;
      var cy = radius;
      var rotation = 0.0;
      var strokedashoffset = 0.0;
      var strokedasharray = 0.0;
      var r = 0.0;
      var sCircles = "";
      damages.sort(function (d1, d2) {
        return new Date(d1.UPDATEDON) - new Date(d2.UPDATEDON);
      });
      for (var i = 0; i < damages.length; i++) {
        if (damages[i].TYPE !== "Mechanical") {
          let xArcLen = damages[i].ARCLEN;
          let xShell = damages[i].SHELL;
          let xDepth = damages[i].DEPTH;
          rotation = damages[i].ORIENT * -1;

          if (!xShell) xShell = 0;

          let arcPercentage = (xArcLen * 100) / this.origCirc;
          let scaledArcLen = (this.circumference * arcPercentage) / 100;
          let shellPercentage = (xShell * 100) / this.origCirc;
          let scaledShell = (this.circumference * shellPercentage) / 100;
          let depthPercentage = (xDepth * 100) / this.origCirc;
          let scaledDepth = (this.circumference * depthPercentage) / 100;
          let scaledOrient = (180 * arcPercentage) / 100;
          rotation -= scaledOrient;

          for (var j = scaledShell * 3; j < scaledDepth * 3; j++) {
            r = this.radius - j;
            let theta = 3.6 * (scaledArcLen / this.circumference) * 100;
            let arcL = theta * 0.0174 * r;

            strokedashoffset = r * 20 - arcL;
            strokedasharray = r * 20;

            //Builds the SVG Circle String
            let type = damages[i].TYPE;
            let maxDate = new Date(
              Math.max(...damages.map((e) => new Date(e.UPDATEDON)))
            ).toJSON();

            if (maxDate == damages[i].UPDATEDON) {
              type = "Recent";
            } else {
              type = "Old";
            }
            sCircles +=
              '<circle id="c" cx="' +
              this.symetricRound(parseFloat(cx)) +
              '" cy="' +
              this.symetricRound(parseFloat(cy)) +
              '" r="' +
              r +
              '" fill="none" stroke="' +
              this.getColorForType(type) +
              '" stroke-width="' +
              this.stroke +
              '" stroke-dashoffset="' +
              strokedashoffset +
              '" stroke-dasharray="' +
              strokedasharray +
              '" transform="rotate(' +
              rotation +
              " " +
              this.symetricRound(parseFloat(cx)) +
              " " +
              this.symetricRound(parseFloat(cy)) +
              ')" + data-type= "' +
              type +
              '" />';
          }
        }
      }
      return sCircles;
    },
    getMechanicalDamagesArc(damages) {
      var sMCircles = "";
      damages.sort(function (d1, d2) {
        return new Date(d1.UPDATEDON) - new Date(d2.UPDATEDON);
      });

      for (var i = 0; i < damages.length; i++) {
        if (damages[i].TYPE === "Mechanical") {
          let type = damages[i].TYPE;
          let maxDate = new Date(
            Math.max(...damages.map((e) => new Date(e.UPDATEDON)))
          ).toJSON();
          if (maxDate == damages[i].UPDATEDON) {
            type = "Recent";
          } else {
            type = "Old";
          }

          let xArcLen = damages[i].ARCLEN;
          let arcPercentage = (xArcLen * 100) / this.origCirc;
          let scaledArcLen = (this.circumference * arcPercentage) / 100;

          if (damages[i].ORIENT === 0) {
            let x = 49 - scaledArcLen;
            if (xArcLen != this.origCirc / 2) x = 50 - scaledArcLen + 4;
            if (xArcLen < this.origCirc / 6) {
              x = 50 - scaledArcLen + 3.25;
            }
            sMCircles += `<g>
              <defs>
                  <clipPath id="cut-off-bottom${i}">
                    <rect x="${x}" y="0" width="100" height="100" fill="${this.getColorForType(
              type
            )}"></rect>
                  </clipPath>
                </defs>

                <circle cx="25" cy="24" r="24" clip-path="url(#cut-off-bottom${i})" fill="${this.getColorForType(
              type
            )}"></circle>
              </g>`;
          } else if (damages[i].ORIENT === 45) {
            let h = scaledArcLen;
            if (xArcLen != this.origCirc / 2) h = scaledArcLen - 4;
            if (xArcLen < this.origCirc / 6) {
              h = scaledArcLen - 3.25;
            }
            sMCircles += `<g>
              <defs>
                  <clipPath id="cut-off-bottom${i}">
                    <rect x="0" y="-25" width="100" height="${h}" transform="rotate(45)"></rect>
                  </clipPath>
                </defs>

                <circle cx="24" cy="24" r="24" clip-path="url(#cut-off-bottom${i})" fill="${this.getColorForType(
              type
            )}"></circle>
              </g>`;
          } else if (damages[i].ORIENT === 90) {
            let y = scaledArcLen;
            if (xArcLen != this.origCirc / 2) y = scaledArcLen - 4;
            sMCircles += `<g>
                <defs>
                  <clipPath id="cut-off-bottom${i}">
                    <rect x="0" y="-1" width="100" height="${y}" fill="${this.getColorForType(
              type
            )}"></rect>
                  </clipPath>
                </defs>
                <circle cx="24" cy="23" r="24" clip-path="url(#cut-off-bottom${i})" fill="${this.getColorForType(
              type
            )}"></circle>
              </g>`;
          } else if (damages[i].ORIENT === 135) {
            let y = -9 - scaledArcLen;
            if (xArcLen != this.origCirc / 2) y = -scaledArcLen - 5;
            if (xArcLen < this.origCirc / 6) {
              y = -scaledArcLen - 5.5;
            }
            sMCircles += `<g>
              <defs>
                  <clipPath id="cut-off-bottom${i}">
                    <rect x="-24" y="${y}" width="100" height="100" transform="rotate(135)"></rect>
                  </clipPath>
                </defs>

                <circle cx="24" cy="24" r="24" clip-path="url(#cut-off-bottom${i})" fill="${this.getColorForType(
              type
            )}"></circle>
              </g>`;
          } else if (damages[i].ORIENT === 180) {
            let w = scaledArcLen;
            if (xArcLen != this.origCirc / 2) w = scaledArcLen - 4;
            if (xArcLen < this.origCirc / 6) {
              w = scaledArcLen - 3.25;
            }
            sMCircles += `<g>
              <defs>
                  <clipPath id="cut-off-bottom${i}">
                    <rect x="-1" y="0" width="${w}" height="100" fill="${this.getColorForType(
              type
            )}"></rect>
                  </clipPath>
                </defs>

                <circle cx="24" cy="24" r="24" clip-path="url(#cut-off-bottom${i})" fill="${this.getColorForType(
              type
            )}"></circle>
              </g>`;
          } else if (damages[i].ORIENT === -45) {
            let y = 59 - scaledArcLen;
            if (xArcLen != this.origCirc / 2) y = 63 - scaledArcLen;
            if (xArcLen < this.origCirc / 6) {
              y = 62.25 - scaledArcLen;
            }
            sMCircles += `<g>
                <defs>
                  <clipPath id="cut-off-bottom${i}">
                    <rect x="-24" y="${y}" width="100" height="100" transform="rotate(-45)"></rect>
                  </clipPath>
                </defs>

                <circle cx="24" cy="24" r="24" clip-path="url(#cut-off-bottom${i})" fill="${this.getColorForType(
              type
            )}"></circle>
              </g>`;
          } else if (damages[i].ORIENT === -90) {
            let y = 49 - scaledArcLen;
            if (xArcLen != this.origCirc / 2) y = 50 - scaledArcLen + 4;
            if (xArcLen < this.origCirc / 6) {
              y = 50 - scaledArcLen + 2.5;
            }
            sMCircles += `<g>
                <defs>
                  <clipPath id="cut-off-bottom${i}">
                    <rect x="0" y="${y}" width="200" height="100" fill="${this.getColorForType(
              type
            )}"></rect>
                  </clipPath>
                </defs>
                <circle cx="24" cy="24" r="24" clip-path="url(#cut-off-bottom${i})" fill="${this.getColorForType(
              type
            )}"></circle>
              </g>`;
          } else {
            let y = 25 - scaledArcLen;
            if (xArcLen != this.origCirc / 2) y = 25 - scaledArcLen + 4;
            if (xArcLen < this.origCirc / 6) {
              y = 25 - scaledArcLen + 3.5;
            }
            sMCircles += `<g>
              <defs>
                  <clipPath id="cut-off-bottom${i}">
                    <rect x="-24" y="${y}" width="100" height="100" transform="rotate(45)"></rect>
                  </clipPath>
                </defs>

                <circle cx="24" cy="24" r="24" clip-path="url(#cut-off-bottom${i})" fill="${this.getColorForType(
              type
            )}"></circle>
              </g>`;
          }
        }
      }

      return sMCircles;
    },
    //Simple radius to circumference utility
    calculateCircumference(radius, factor) {
      return (2 * Math.PI * radius) / factor;
    },
    //Simple circumference to radius utility
    calculateRadius(circumference) {
      return Math.round(circumference / Math.PI / 2);
    },
    //Color switch by tpe for damage drawn on svg
    getColorForType(damageType) {
      switch (damageType) {
        case "EnclosedPocket":
          return this.EnclosedPocketColor;
        case "ExposedPocket":
          return this.ExposedPocketPocketColor;
        case "Mechanical":
          return this.MechanicalPocketColor;
        case "HollowPole":
          return this.HollowPoleColor;
        case "Old":
          return this.ExposedPocketPocketColor;
        case "Recent":
          return this.EnclosedPocketColor;
        default:
          return this.UnknownColor;
      }
    },
    symetricRound(num) {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    refresh: function (newVal, oldVal) {
      this.draw(this.damages);
    },
  },
  mounted() {
    let $vm = this;
    //on mount till the plumbing is in
    $vm.draw(this.damages);
  },
};
</script>

<style scoped>
div.ex1 {
  width: 25%;
  margin: auto;
}
</style>
