<template>
  <div class="mainPage">
    <div class="mainPageContent">
      <div class="contentPanel pb-0">
        <div class="headerLogo">
          <img
            src="../../assets/logo_b.png"
            alt="Osmose"
            @click="openDashboard()"
          />
        </div>
        <h6 class="poleId"><strong>Pole ID:</strong> {{ poleData.poleId }}</h6>
      </div>
      <div class="damageBox">
          <div class="listBox">
              <div class="graph">
                  <div class="graphPart" :class="{ graphPartZoom: zoomActive }">
                      <img class="expand"
                           src="../../assets/expand.png"
                           alt="expand"
                           v-on:click="zoomActive = !zoomActive" />
                      <div v-on:click="zoomActive = !zoomActive" class="brdGraph">
                          <CrossSection :damages="damages"
                                        :origCirc="poleData.origCircumference"
                                        :shellRotCirc="poleData.shellRotCircumference"
                                        :refresh="refresh"
                                        style="
                    margin: 6px auto 6px auto;
                    width: 50%;
                    min-width: 150px;
                  " />
                      </div>
                      <a href="javascript: void(0);"
                         class="closeZoomIcon"
                         v-on:click="zoomActive = !zoomActive">
                          <img src="../../assets/two-arrows.png" alt="collapse" />
                      </a>
                  </div>
              </div>
              <div class="boxTitle">
                  <ul class="listInline">
                      <li>
                          <strong>Strength: </strong>&nbsp;<span>{{ getComputedStrength }}%</span>
                      </li>
                      <li>
                          <strong class="mobilePort_1">Eff. Circ:</strong>
                          <strong class="mobilePort1">Effective Circumference:</strong>&nbsp;<span>{{ getEffCir }}"</span>
                      </li>
                      <li>
                          <strong class="mobilePort_1">Orig. Circ: </strong>
                          <strong class="mobilePort1">Original Circumference: </strong>&nbsp;<span> {{ poleData.origCircumference }}"</span>
                      </li>
                      <li>
                          <strong class="mobilePort_1">SR Circ: </strong>
                          <strong class="mobilePort1">ShellRot Circumference: </strong>&nbsp;<span> {{ poleData.shellRotCircumference }}"</span>
                      </li>
                      <li class="mrgtop15px">
                          <a href="javascript: void(0);"
                             class="editPole"
                             v-if="standalone"
                             @click="editPoleData">Edit Pole Data</a>
                      </li>
                  </ul>
              </div>
          </div>
          <div class="evalution">
              <h3 class="title2">Damage Instances</h3>
              <div class="listOrt" v-for="damage in damageList" :key="damage.ID" style="height:50px">
                  <img v-if="damage.TYPE == 'ExposedPocket'"
                       alt="Exposed Pocket"
                       src="../../assets/arc1.png"
                       :style="`transform: rotate(${calRotation(damage.ORIENT)}deg);`" />
                  <img v-else-if="damage.TYPE == 'EnclosedPocket'"
                       alt="Enclosed Pocket"
                       src="../../assets/arc2.png"
                       :style="`transform: rotate(${calRotation(damage.ORIENT)}deg);`" />
                  <img v-else-if="damage.TYPE == 'Mechanical'"
                       alt="Mechanical Damage"
                       src="../../assets/arc4.png"
                       :style="`transform: rotate(${calRotation(damage.ORIENT)}deg);`" />
                  <img v-else-if="damage.TYPE == 'HollowPole'"
                       alt="Hollow Pole"
                       src="../../assets/arc3.png"
                       :style="`transform: rotate(${calRotation(damage.ORIENT)}deg);`" />
                  <strong v-if="damage.TYPE == 'ExposedPocket'"
                          @click="editDamage(damage)">
                      {{ damage.ARCLEN }} | {{ damage.DEPTH }}
                  </strong>
                  <strong v-else-if="damage.TYPE == 'EnclosedPocket'"
                          @click="editDamage(damage)">
                      {{ damage.ARCLEN }} | {{ damage.SHELL }} | {{ damage.DEPTH }}
                  </strong>
                  <strong v-else-if="damage.TYPE == 'Mechanical'"
                          @click="editDamage(damage)">{{ damage.ARCLEN }}</strong>
                  <strong v-else-if="damage.TYPE == 'HollowPole'"
                          @click="editDamage(damage)">
                      {{ damage.shellQuads.shellLoL }}, {{ damage.shellQuads.shell90 }},
                      {{ damage.shellQuads.shell_LoL }},
                      {{ damage.shellQuads.shell_90 }}
                  </strong>
                  <a href="javascript: void(0);" v-if="allowEditting" @click="removeDamageAlert(damage)">REMOVE</a>
                  <a href="javascript: void(0);" v-if="allowEditting==false" @click="editDamage(damage)">VIEW</a>
              </div>
              <button class="btnblueOutline" v-if="allowEditting" @click="addDamageRecord()">
                  <span>+</span>
              </button>
              <button class="btnblueOutline" v-if="fromFulcrumAndNoDamages">
                  <span>No Damages</span>
              </button>              <button class="btnblue mrgSave" v-if="standalone" @click="onSave">SAVE</button>
              <button class="btnblue mrgSave" v-if="fromFulcrumAndAllowEditting" @click="onSave">SAVE and EXIT</button>
              <button class="btnblueOutline"
                      v-if="standalone"
                      @click="createPole()">
                  CREATE NEW POLE
              </button>
          </div>
          
          <div class="textCenter">
              <a href="javascript: void(0);"
                 class="backLink txtUppercase"
                 @click="onCancel()">{{cancelText}}
              </a>
          </div>
      </div>

      <shared-footer></shared-footer>
      <div class="popUp" v-if="popup">
        <div class="popUpBox">
          <p class="popupText">{{ popupMsg }}</p>
          <div class="modalFooter">
            <button class="btnRounded" v-on:click="closePrompt()">
              CANCEL
            </button>
            &nbsp;&nbsp;
            <button class="btnRoundedFull" v-on:click="confirmPrompt()">
              {{ popupBtnTxt }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleIDB from "../../api/indexedDBService.js";
import SharedFooter from "../../components/SharedFooter.vue";
import { store } from "../../store/index.js";
import { mapGetters } from "vuex";
import { StrengthCalcV4, SourceTypes } from "strengthcalc-library";
import CrossSection from "./CrossSection.vue";
import { v4 as uuidv4 } from "uuid";
    import { finish } from 'fulcrum-extensions';
    import Eruda from 'eruda';

export default {
  name: "Evaluation",
  data: () => ({
    poleData: {
      deviceId: "",
      poleId: "",
      origCircumference: "",
      shellRotCircumference: "",
          damages: [], 
      editAction: ""
      },
      poles: [],
      poleRecords: [],

      returnData: {
          rem_str: 0, 
          circ: 0,
          sr_circ: 0,
          eff_circ: 0,
          mech_dmg:0,
          enc_pkt: 0,
          exp_pkt: 0,
          hollow: 0
     },
      strengthCalcData: {
          circ: 0,
          sr_circ: 0,
          eff_circ: 0,
          rem_str: 0, 
         damages: {}
      },
    zoomActive: false,
    strength: "",
    effCir: "",
    popup: false,
    popupMsg: "",
    popupAction: "",
    popupBtnTxt: "",
    refresh: false,
    damages: [],
    damageList: [],
    damageObj: {},
    damageRemoved: false,
    rotation: 0,
    removedDamages: [],
    standalone: true,
    fromFulcrum: false,
    isCanceled: false,
    allowEditting: true,
    fromFulcrumAndAllowEditting: false,
    fromFulcrumAndNoDamages: false,
    cancelText: "Cancel",

    createNewPole: false, //rs: variable to check if the onCancel func was called inside the create new pole button
  }),

  components: {
    "shared-footer": SharedFooter,
    CrossSection,
  },

  computed: {
    ...mapGetters(["getPoleRecord"]),

      calculateStrength: function () {
          let self = this;
      let SC = new StrengthCalcV4(
        parseFloat(this.poleData.origCircumference),
        parseFloat(this.poleData.shellRotCircumference)
      );
        //debugger;
          self.returnData.mech_dmg = 0;
          self.returnData.enc_pkt = 0;
          self.returnData.exp_pkt = 0;
          self.returnData.hollow = 0;
          let hollowPoleDepths = 0;
          let hollowPoleCount = 0;

      if (this.damageList) {
        this.damageList.forEach((damage) => {
          if (damage.TYPE === "ExposedPocket") {
            SC.AddExposedPocket(
              parseFloat(damage.ARCLEN),
              parseFloat(damage.ORIENT),
              parseFloat(damage.DEPTH),
                SourceTypes.Manual
            );
              this.returnData.exp_pkt++

          }
          if (damage.TYPE === "EnclosedPocket") {
            SC.AddEnclosedPocket(
              parseFloat(damage.ARCLEN),
              parseFloat(damage.ORIENT),
              parseFloat(damage.DEPTH),
              parseFloat(damage.SHELL),
              SourceTypes.Manual
            );
              this.returnData.enc_pkt++;
          }
          if (damage.TYPE === "Mechanical") {
            SC.AddMechanicalDamage(
              parseFloat(damage.ARCLEN),
              parseFloat(damage.ORIENT),
              SourceTypes.Manual
            );
              this.returnData.mech_dmg++;
          }
          if (damage.TYPE === "HollowPole") {
            SC.AddHollowPole(
              parseFloat(damage.ARCLEN),
              parseFloat(damage.DEPTH),
              [
                damage.shellQuads.shellLoL,
                damage.shellQuads.shell90,
                damage.shellQuads.shell_LoL,
                damage.shellQuads.shell_90,
              ],
              SourceTypes.Manual
            );
              hollowPoleDepths += damage.shellQuads.shellLoL;
              hollowPoleDepths += damage.shellQuads.shell90;
              hollowPoleDepths += damage.shellQuads.shell_LoL;
              hollowPoleDepths += damage.shellQuads.shell_90;
              hollowPoleCount += 4;
          }
        });
          if (SC.CalculateStrength()) {
              if (hollowPoleCount != 0) {
                  self.returnData.hollow = hollowPoleDepths / hollowPoleCount;
              }
          let data = SC.serializeSTRCALC();
          return JSON.parse(data);
        } else {
          return {};
        }
      }
      return {};
    },

      getComputedStrength: function () {
          if (this.calculateStrength.RemainingStrength)
              return this.calculateStrength.RemainingStrength;
      return 0;
    },

    getEffCir: function () {
      if (this.calculateStrength.EffectiveCircumference)
        return this.calculateStrength.EffectiveCircumference;
      return 0;
    },
  },

        mounted() {
            
  
        },
        created() {
            //debugger;
            // use this for visibility 
            if (localStorage.getItem("ErudaEnabled")==="Yes")
                Eruda.init();
            this.standalone = !store.getters.fromFulcrum;
            this.fromFulcrum = store.getters.fromFulcrum;

            //debugger;
            // set the Read Only and From Fulcrum flag
            if (this.fromFulcrum) {
                this.allowEditting = this.fromFulcrumAndAllowEditting = (localStorage.getItem("readOnly") == "false");
                this.cancelText = this.allowEditting ? "CANCEL" : "Exit Read Only";
            }
    // debugger;

            if (store.getters.fromFulcrum && localStorage.getItem("InitialLoad") === "Yes") {
                this.loadFromFulcrum();
                localStorage.setItem("InitialLoad", "No");
               }
  
            else {
                this.poleData = JSON.parse(this.getPoleRecord);
                this.getDamages();
           }
            this.poleData.deviceId = localStorage.getItem("deviceID");           
        },
        
  methods: {
    openDashboard() {
      this.removeUnsavedDamage();
    },
      loadFromFulcrum()
      {
          //debugger;
          let self = this;
          //debugger;
          let startParams = JSON.parse(localStorage.getItem("startParameters"));
          this.poleData.deviceId = localStorage.getItem("deviceID");
          this.poleData.poleId = startParams.struct_id;

          this.poleData.origCircumference = startParams.circ;
          this.poleData.shellRotCircumference = startParams.sr_circ;
          localStorage.setItem("readOnly", startParams.read_only);
          this.allowEditting = !startParams.read_only;
          this.fromFulcrumAndAllowEditting = this.allowEditting;

          this.cancelText = this.allowEditting ? "CANCEL" : "Exit Read Only";

          store.dispatch("setPoleRecord", JSON.stringify(this.poleData));

          try {
              //debugger;
              // var damageRecs = XMLSerializer.parse(atob(startParams.damages));
              var damageRecs = JSON.parse(atob(startParams.sasc_dtl));

              for (let i = 0; i < damageRecs.damages.length; i++) {
                  self.updateDamages(damageRecs.damages[i]);
              }
              this.poleData.damages = damageRecs.damages;
              //damageRecs.damages.forEach((damage) => {
              //    self.updateDamages(damage);
              //});
              //store.dispatch("setDamageRecs")

          }
          catch (e) {
              console.log(e);
          }
          //debugger;
          if (!this.allowEditting && this.poleData.damages.length === 0)
              this.fromFulcrumAndNoDamages = true;
          store.dispatch("setPoleRecord", JSON.stringify(this.poleData));


      },
      
      getAll(store, db_name) {
          try {
              return SimpleIDB.SimpleIDB.getAll(store, db_name);
          } catch (e) {
              console.log(e);
          }
      },
    editPoleData() {
      let editPole = true;
      this.$router.replace({
        name: "startNewPole",
        params: { editPole },
      });
    },

      addDamageRecord() {
      store.dispatch("setPoleRecord", JSON.stringify(this.poleData));
      this.$router.push("/damageRecord");
    },

      onSave() {
      let self = this;
      let pole = this.poleData;
      this.poleData.updatedOn = new Date();
      if (
        this.poleData.oldPoleId != undefined &&
        this.poleData.editAction &&
        this.poleData.editAction == "Overwrite"
      ) {
        let key = this.poleData.oldPoleId;
        this.$delete(this.poleData, "oldPoleId");
        this.$delete(this.poleData, "editAction");
        this.deleteDamages();
        this.removeObject(key, "pole-data", "SASC_DB").then(() => {
          self.savePole(pole.poleId, JSON.stringify(pole));
        });
        this.poleData.editAction == "";
        store.dispatch("setPoleRecord", JSON.stringify(this.poleData));
      } else {
        if (this.poleData.oldPoleId) this.$delete(this.poleData, "oldPoleId");
        this.savePole(this.poleData.poleId, JSON.stringify(this.poleData));
      }
        //debugger;
        this.returnData.rem_str = this.calculateStrength.RemainingStrength;
        this.returnData.circ = this.poleData.origCircumference;
        this.returnData.sr_circ = this.poleData.shellRotCircumference;
        this.returnData.eff_circ = this.calculateStrength.EffectiveCircumference;

        this.strengthCalcData.circ = this.poleData.origCircumference;
        this.strengthCalcData.sr_circ = this.poleData.shellRotCircumference;
        this.strengthCalcData.eff_circ = this.calculateStrength.EffectiveCircumference;
        this.strengthCalcData.rem_str = this.calculateStrength.RemainingStrength; 
        this.strengthCalcData.damages = this.damageList;

        // debugger;
        //let returnString = this.strengthCalcData.serializeSTRCALC();
        //this.returnData.strengthCalcDetail = returnString;
        this.returnData.sasc_dtl = btoa(JSON.stringify(this.strengthCalcData));

        //Fulcrum.finish({ simple_result: JSON.stringify(this.returnData) });
        finish({ simple_result: JSON.stringify(this.returnData) });
       
        this.navigateAfterSaveOrCancel();

        return;

    },

    savePole(key, val) {
      if (this.damageRemoved) {
        this.removedDamages.forEach((d) => {
          this.removeObject(d.ID, "damage-data", "SASC_DB").then(() => {
            this.insertObject("pole-data", "SASC_DB", key, val).then(() => {
            });
          });
        });
      } else {
        this.insertObject("pole-data", "SASC_DB", key, val).then(() => {
        });
      }
    },

    insertObject(store, db_name, key, val) {
      try {
        let jsonVal = val.includes("{") ? JSON.parse(val) : val;
        return SimpleIDB.SimpleIDB.set(key, jsonVal, store, db_name);
      } catch (e) {
        console.log(e);
      }
    },


    createPole() {
      this.createNewPole = true;
      this.onCancel(); //rs: calling the popup flow after setting the createNewPole flag to true
    },

      onCancel() {
          if (this.fromFulcrum && !this.allowEditting) {
              this.closeDownExtension();

              return;

          }
      this.popupMsg = "Changes will not be saved. Continue?";
      this.popupAction = "Cancel";
      this.popupBtnTxt = "OK";
      this.popup = true;
    },

      closePrompt() {
          //debugger;
      this.popup = false;
        this.createNewPole = false;
        
    },

    confirmPrompt() {
        this.popup = false;
        //debugger;
        if (this.popupAction === "Cancel") {

            if (store.getters.fromFulcrum) {
                this.closeDownExtension();
                return;
            }
            this.navigateAfterSaveOrCancel();
        }

        else
        {
            if (this.fromFulcrum) {
                //debugger;
                var index = this.poleData.damages.findIndex((dam) => dam.ID == this.damageObj.ID);
                this.poleData.damages.splice(index, 1);
                this.damageList.splice(index, 1);
                store.dispatch("setPoleRecord", JSON.stringify(this.poleData));
            }
            else {
                //debugger;
                //this.removeUnsavedDamage();
                if (this.popupAction === "Remove") this.removeDamage(this.damageObj);
            }
        }
        
    },
      closeDownExtension() {
          this.returnData.rem_str = -1;

          this.strengthCalcData.circ = this.poleData.origCircumference;
          this.strengthCalcData.sr_circ = this.poleData.shellRotCircumference;
          this.strengthCalcData.damages = {};


          this.returnData.strengthCalcDetail = this.strengthCalcData;

          finish({ simple_result: JSON.stringify(this.returnData) });

          this.navigateAfterSaveOrCancel();
          return;
      },
      removeUnsavedDamage() {
          //debugger;
      let self = this;
      let origCirc = this.poleData.origCircumference;
      this.getObject(this.poleData.poleId, "pole-data", "SASC_DB").then(
          (res) => {
              if (res) {
                  if (origCirc != res.origCircumference)
                      self.clearDamages();
              }
              else {
                  this.isCanceled = true;

                  self.deleteDamages();
              }

              //this.navigateAfterSaveOrCancel();
          }
      );
        
    },

    clearDamages() {
      this.poleData.damages = [];
      this.onSave();
    },
      navigateAfterSaveOrCancel() {
          //debugger;
        if (store.getters.fromFulcrum) {
                // send an empty payload back to fulcrum(or do some other cancel process)

                window.close();
            }
  
        else {
            //debugger;

            if (this.createNewPole) this.$router.replace("/startNewPole");
            // rs: going to the new pole page
            else
                this.$router.replace("/dashboard");

        }
      },
     
      
      deleteDamages() {
          //debugger;
      if (this.poleData.damages.length > 0) {
        //let editAction = this.poleData.editAction;
        this.poleData.damages.forEach((damage, index) => {
          if (this.isCanceled || this.damageList.includes(damage.ID)) {
            this.removeObject(damage.ID, "damage-data", "SASC_DB").then(
              () => {
                if (index == this.poleData.damages.length - 1) {
                //    this.navigateAfterSaveOrCancel();
                }
              }
            );
          } else {
            //if (editAction !== undefined) {
            //    this.navigateAfterSaveOrCancel();
            //}
          }
        });
      } else {
          //this.navigateAfterSaveOrCancel();

      }
    },

      getDamages() {
          let self = this;
          //debugger;
          //debugger;
         if (this.poleData.damages.length > 0) {
              this.poleData.damages.forEach((damage) => {
                  if (self.standalone) {
                      //debugger;
                      this.getObject(damage.ID, "damage-data", "SASC_DB")
                          .then((res) => { self.updateDamages(res); });
                  }
                  else {
                      let dmg = damage;
                      //debugger;
                      self.updateDamages(dmg);
                  }
              });
          }
          else {
              this.setRefresh();
          }
      },

    getObject(key, store, db_name) {
      try {
        return SimpleIDB.SimpleIDB.get(key, store, db_name);
      } catch (e) {
        console.log(e);
      }
    },

      updateDamages(damage) {
          //debugger;
       if (damage.TYPE == "HollowPole") {
        this.createEncPocLoL(damage);
        this.createEncPoc90(damage);
        this.createEncPoc_LoL(damage);
        this.createEncPoc_90(damage);
      } else {
        this.damages.push({ ...damage });
      }
      this.damageList.push({ ...damage });
      this.setRefresh();
    },

    createEncPocLoL(damage) {
      var encPocLoL = { ...damage };
      encPocLoL.ID = uuidv4();
      encPocLoL.ORIENT = 0;
      encPocLoL.SHELL = damage.shellQuads.shellLoL;
      encPocLoL.TYPE = "EnclosedPocket";
      encPocLoL.ParentID = damage.ID;

      this.damages.push({ ...encPocLoL });
    },

    createEncPoc90(damage) {
      var encPoc90 = { ...damage };
      encPoc90.ID = uuidv4();
      encPoc90.ORIENT = 90;
      encPoc90.SHELL = damage.shellQuads.shell90;
      encPoc90.TYPE = "EnclosedPocket";
      encPoc90.ParentID = damage.ID;

      this.damages.push({ ...encPoc90 });
    },

    createEncPoc_LoL(damage) {
      var encPoc_LoL = { ...damage };
      encPoc_LoL.ID = uuidv4();
      encPoc_LoL.ORIENT = 180;
      encPoc_LoL.SHELL = damage.shellQuads.shell_LoL;
      encPoc_LoL.TYPE = "EnclosedPocket";
      encPoc_LoL.ParentID = damage.ID;

      this.damages.push({ ...encPoc_LoL });
    },

    createEncPoc_90(damage) {
     var encPoc_90 = { ...damage };
      encPoc_90.ID = uuidv4();
      encPoc_90.ORIENT = -90;
      encPoc_90.SHELL = damage.shellQuads.shell_90;
      encPoc_90.TYPE = "EnclosedPocket";
      encPoc_90.ParentID = damage.ID;

      this.damages.push({ ...encPoc_90 });
    },

    setRefresh() {
      this.refresh = !this.refresh;
      return true;
    },

    removeDamageAlert(damage) {
      this.popupMsg = "This action cannot be undone. Continue?";
      this.popupAction = this.popupBtnTxt = "Remove";
      this.damageObj = damage;
      this.popup = true;
    },

      removeDamage(damage) {
      this.refreshDamages(damage);
      this.removedDamages.push(damage);
    },

    removeObject(key, store, db_name) {
      try {
        return SimpleIDB.SimpleIDB.remove(key, store, db_name);
      } catch (e) {
        console.log(e);
      }
    },

    refreshDamages(damage) {
    this.poleData.damages.forEach((d) => {
        if (d.ID === damage.ID) {
          this.poleData.damages.splice(this.poleData.damages.indexOf(d), 1);
        }
      });

      this.damageList.splice(this.damageList.indexOf(damage), 1);
      if (damage.TYPE == "HollowPole") {
        let tempDamages = this.damages.slice();
        tempDamages.forEach((d) => {
          if (d.ParentID && d.ParentID == damage.ID) {
            this.damages.splice(this.damages.indexOf(d), 1);
          }
        });
      } else {
        var index = this.damages.findIndex((dam) => dam.ID == damage.ID);
        this.damages.splice(index, 1);
      }
      this.damageRemoved = true;
      this.setRefresh();
    },

    editDamage(damage) {
      let damageRecord = {
        type: damage.TYPE,
        poleData: this.poleData,
        damage: damage,
      };
      store.dispatch("setDamageRecord", JSON.stringify(damageRecord));
      if (damageRecord.type == "HollowPole") this.$router.push("/hollowPole");
      else this.$router.push("/damageDetail");
    },

    calRotation(orient) {
      return parseInt(orient) * -1;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.graph {
  min-width: 162px;
}
.graphPart {
  // border: 1px solid #1c3766;
  position: relative;
  .expand {
    position: absolute;
    right: 10px;
    top: 4px;
    width: 16px;
  }
  .closeZoom {
    display: none;
    color: #0072ff;
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    text-align: center;
  }
  .closeZoomIcon {
    display: none;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
.graphPartZoom {
  position: fixed;
  margin: auto;
  width: 100%;
  left: 0%;
  top: 0%;
  height: 100%;
  z-index: 10;
  background: #fff;
  .expand {
    display: none;
  }
  .ex1 {
    width: 50vh !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .closeZoom,
  .closeZoomIcon {
    display: block;
  }
}
.mrgtop15px {
  margin-top: 15px;
}
.boxTitle {
  .listInline {
    li:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}
.title2 {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  font-weight: bold;
  color: #003468;
  margin-top: 0px;
}
.editPole {
  color: #03a9f5 !important;
}
.evalution {
  width: 310px;
  margin: auto;
  padding: 10px 0px;
}

.boxCircle {
  background: #003468;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  text-align: center;
  div {
    width: 70px;
    margin: 16px auto;
  }
}
.poleId {
  font-size: 16px;
  font-weight: normal;
  margin: 10px 0px;
}
.damageBox {
  padding-bottom: 50px;
}
.listInlinecst {
  text-align: left;
  li a {
    color: #000;
    text-decoration: none;
    font-size: 16px;
    display: block;
    border-bottom: 1px solid #d5d5d5;
    padding: 15px 5px;
    margin-bottom: 10px;
  }
}
.popUp {
  text-align: center;
  position: fixed;
  top: 0px;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.popUpBox {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 5px #d2e4ff;
  padding: 0px;
  width: 300px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.popupText {
  padding: 20px 20px;
}
.modalFooter {
  border-top: 1px solid #d5d5d5;
  padding: 10px 10px;
}
.contactSection {
  margin: 12% 0px;
}
.btnRounded {
  background: #f1f1f1;
  border: 2px solid #f1f1f1;
  padding: 6px;
  border-radius: 50px;
  color: #2c95ff;
  min-width: 90px;
  cursor: pointer;
}
.btnRoundedFull {
  background: #2c95ff;
  border: 2px solid #2c95ff;
  padding: 6px;
  border-radius: 50px;
  color: #fff;
  min-width: 90px;
  cursor: pointer;
}
.mainPage {
  height: calc(100vh - 50px);
}
.headerLogo {
  img {
    width: 180px;
  }
}
.mainPageContent {
  margin: auto;
}
.listInline {
  list-style: none;
  padding-left: 0;
  font-size: 14px;
  margin: 5px 0px;
  .listItem {
    display: inline-block;
    margin-right: 1rem;
    a {
      text-decoration: none;
    }
    .active {
      font-weight: 700;
    }
  }
  .listItem:last-child {
    margin-right: 0rem;
  }
}
.listOrt {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  strong {
    font-size: 18px;
    flex: 50%;
    font-weight: normal;
  }
  img {
    width: 30px;
    margin: 0px 10px;
    flex: 10%;
  }
  a {
    text-decoration: none;
    color: #003468;
    font-size: 14px;
    font-weight: bold;
    flex: 40%;
    text-align: center;
    border-left: 1px solid #ccc;
    line-height: 50px;
  }
}
.title1 {
  margin: 3% 0px;
  text-align: center;
  color: #003468;
  font-weight: 600;
  font-size: 20px;
}
.btndef {
  background: #ccc;
  border-radius: 5px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  border: 0px;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  margin: 18px 0px;
  pointer-events: none;
}
.btnblue {
  background: #003468;
  border-radius: 5px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  border: 2px solid #003468;
  width: 100%;
  margin: 18px 0px;
  pointer-events: all !important;
}
.mrgSave {
  margin: 0px 0px 0px 0px !important;
}
.btnblueOutline {
  background: #fff;
  border-radius: 5px;
  border: 2px solid #003468;
  padding: 16px;
  color: #003468;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  margin: 13px 0px;
  pointer-events: all !important;
  span {
    font-weight: bold;
    font-size: 2rem;
    line-height: 0px;
    padding: 10px;
    display: block;
  }
}
.error {
  color: #f00;
  font-size: 12px;
}
.bottomFixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  background: #fff;
  z-index: 1;
  .listInline a {
    color: #003468;
  }
}
.backLink {
  color: #003468;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
}
.contentPanel {
  width: 350px;
  text-align: center;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 50px;
}
.info1 {
  color: #003468;
  font-size: 14px;
  font-weight: 600;
  margin: 16px 0px;
}
.info2 {
  color: #2c95ff;
  font-size: 14px;
  font-weight: 600;
  margin: 8px 0px;
  text-decoration: none;
  display: inline-block;
}

.Box1 {
  border-top: 1px solid #d5d5d5;
  padding: 15px 0px;
  cursor: pointer;
}
.listBox {
  width: 310px;
  margin: auto;
  color: #000;
  font-size: 18px;
  display: flex;
  align-items: center;
  position: relative;
}
.boxTitle {
  margin-left: 12px;
}
.pb-0 {
  padding-bottom: 0px;
}

//Tabs Only

@media (min-width: 320px) {
  .maxHgt {
    height: calc(100vh - 55vh);
    overflow: auto;
  }
  .mobilePort_1 {
    display: none;
  }
}
@media (min-width: 375px) {
  .maxHgt {
    height: calc(100vh - 50vh);
  }
}
@media (min-width: 1023.5px) {
  .maxHgt {
    height: calc(100vh - 55vh);
  }
}
@media (min-width: 768px) {
  .boxCircle {
    width: 160px;
    height: 160px;
    div {
      margin: 55px auto;
    }
  }
  .listBox,
  .evalution {
    width: 450px;
  }
  .contentPanel {
    padding-top: 5rem;
    width: 450px;
  }

  .listInline {
    margin: 2rem 0px;
  }
  //   .headerLogo{
  //   img{
  //     width: 230px;
  //     height: 48px;
  //   }
  // }
}
//Desktop only
@media (min-width: 1200px) {
  .listInline {
    margin: 1rem 0px;
  }
  .contentPanel {
    padding-top: 1rem;
  }
  .title1 {
    margin: 3% 0px;
  }
}
@media (max-width: 500px) {
  .mobilePort1 {
    display: none;
  }
  .mobilePort_1 {
    display: inline-block;
  }
}
//Small mobile
@media (max-width: 374.5px) {
  .contentPanel {
    width: 90% !important;
  }
}
</style>

